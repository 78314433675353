import { Routes, Route } from 'react-router-dom';
import { getToken } from "./helpers";
import './App.css';
import StopSkill from './fragments/StopSkill';
// import Home from './fragments/Home';
import Settings from './fragments/Settings';
import Loginpage from './fragments/Login';
import Registerpage from './fragments/RegisterPage';
import Breath from './fragments/Breath';
import ToolBox from './fragments/FavoriteActivities.js';
import QuickCope from './fragments/QuickCope';
import Contact from './fragments/Contact';
import Profile from './Components/Profile/EditProfile';
import Form from './fragments/Form';
import ChooseOption from './fragments/ChooseOption.js';
import RecommendedSkills from './Components/Skills/RecommendedSkills.js';
import FilterSkills from './Components/Skills/FilterSkills.js';
import Featured from './Components/Skills/Featured.js';
import Recommendations from './fragments/Recommended.js';
import Liked from './Components/Skills/Liked.js';
import Faq from './fragments/Faq.js';
import Activities from './fragments/Activities.js';
import Welcome from './fragments/Welcome.js';
import RCA from './fragments/RootCauseAnalysis.js';
import PaymentCancel from './fragments/PaymentCancel.js';
import PaymentSuccess from './fragments/PaymentSuccess.js';
import ForgotPassword from './Components/Login/ForgetPassword.js';
import ResetPassword from './Components/Login/ResetPassword.js';
import StripeSubscription from './Components/Stripe/StripeSubscription.js';
import Dashboard from './MyAccount/dashboard.js';
import SubscriptionDetails from './MyAccount/subscriptionDetails.js';
import Subscription from './MyAccount/subscription.js';
import GoPremium from './fragments/GoPremium.js';
// import BillingAdress from './MyAccount/billingAddress.js';
import OrderDetails from './MyAccount/orderDetails.js';
import OrderThankYou from './MyAccount/OrderThanYou.js';
import TermsAndConditions from './fragments/TermsAndConditions.js';
import FavoriteActivities from './fragments/FavoriteActivities.js';



function App() {

  // console.log(getToken())
  
  return (
    <div className="App">
      <Routes>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
        <Route path="/ChooseOption" element={getToken() ? <ChooseOption /> : <Loginpage />}></Route>
        <Route path="/my-account/order-thank-you" element={getToken() ? <OrderThankYou /> : <Loginpage />}></Route>
        <Route path="/dashboard" element={getToken() ? <Dashboard /> : <Loginpage />}></Route>
        <Route path="/dashboard/subscription" element={getToken() ? <Subscription /> : <Loginpage />}></Route>
        {/* <Route path="/b" element={getToken() ? <BillingAdress /> : <Loginpage />}></Route> */}
        <Route path="/subscriptions/:id" element={getToken() ? <SubscriptionDetails /> : <Loginpage />}></Route>
        <Route path="/orders/:id" element={getToken() ? <OrderDetails /> : <Loginpage />}></Route>
        <Route path="/PaymentSuccess" element={getToken() ? <PaymentSuccess /> : <Loginpage />}></Route>
        <Route path="/Subscription" element={<StripeSubscription />}></Route>
        <Route path="/go-premium" element={getToken() ? <GoPremium /> : <Loginpage />}></Route>
        <Route path="/PaymentCancel" element={getToken() ? <PaymentCancel /> : <Loginpage />}></Route>
        <Route path="/" element={getToken() ? <ChooseOption /> : <Welcome />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="StopSkill" element={getToken() ? <StopSkill /> : <Loginpage />}></Route>
        <Route path="Settings" element={getToken() ? <Settings /> : <Loginpage />}></Route>
        <Route path="Login" element={<Loginpage/>}></Route>
        <Route path="Breath" element={getToken() ? <Breath /> : <Loginpage />}></Route>
        <Route path="Faq" element={<Faq/>}></Route>
        <Route path="favorite-activities" element={getToken() ? <FavoriteActivities /> : <Loginpage />}></Route>
        <Route path="Welcome" element={<Welcome/>}></Route>
        <Route path="RecommendedSkills" element={getToken() ? <RecommendedSkills /> : <Loginpage />}></Route>
        <Route path="FilterSkills" element={getToken() ? <FilterSkills /> : <Loginpage />}></Route>
        <Route path="Featured" element={getToken() ? <Featured /> : <Loginpage />}></Route>
        <Route path="Form" element={getToken() ? <Form /> : <Loginpage />}></Route>
        <Route path="RCA" element={getToken() ? <RCA /> : <Loginpage />}></Route>
        <Route path="Liked" element={getToken() ? <Liked /> : <Loginpage />}></Route>
        <Route path="QuickCope" element={getToken() ? <QuickCope /> : <Loginpage />}></Route>
        <Route path="Recommended" element={getToken() ? <Recommendations /> : <Loginpage />}></Route>
        <Route path="Profile" element={getToken() ? <Profile /> : <Loginpage />}></Route>
        <Route path="Contact" element={<Contact/>}></Route>
        <Route path="Activities" element={getToken() ? <Activities /> : <Loginpage />}></Route>
        <Route path="SignUP" element={<Registerpage/>}></Route>
        <Route path="skills/:slug" element={getToken() ? <StopSkill /> : <Loginpage />} />
      </Routes>
    </div>
  );
}

export default App;
