import React, { useState } from 'react';
import { API, BASE_URL } from '../../constant';
import LoadingImg from "../../Media/loading.gif";

const ForgotPassword = () => {

const [email, setEmail] = useState('');
const [sentEmail, setSentEmail] = useState('');
const [error, setError] = useState("");
const [loading, setLoading] = useState(false);

const handleForgetPassword = async (e) => {
    e.preventDefault();
    try {
        setLoading(true);
        const response = await fetch(`${API}/email/forget-password`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        const data = await response.json();
        if (data?.error) {
            throw data?.error;
          } else {
            setSentEmail(data);
            console.log('Email sent successfully:', data);
          }
          setLoading(false);
    } catch (error) {
        console.error('Error sending email:', error);
        setError(error?.message ?? "Something went wrong!");
        setLoading(false);
    }
};
const content = ()=>{
    if(sentEmail){
        return <div>An email with password reset link has been sent. Please check your email.</div>
    }else if(sentEmail.error){
        return <div>Somthing went wrong, please try again after some time.</div>
    }else{
        return <form onSubmit={handleForgetPassword}>
                <label className="email">
                    <input className="field-with-icon" type="email" value={email} name='forgetPasswordEmail' placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} required/>
                </label>
                <button className="theme-btn" type="submit">Submit</button>
            </form>
            
    }
}
return(
    <div className="common-bg">
        {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
        <div className="container forget-reset-password">
        <div className="banner-box">
                <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                <div className="banner-wrap">
                    <div className="banner-content">
                        <h4 className="title">Forgot your password?</h4>
                    </div>
                    <div className="banner-right-img">
                        <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                    </div>
                </div>
                
            </div>
            <div className="content-wrap white-bg">
                <div className="theme-form">
                    {content()}
                </div>
                <div className="error-message">{error}</div>
                <div className="footer-main">
                    <div className="mask-with-ellips">
                        <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/login_register_bottom_1a090d4194.svg" alt="bg"/>
                    </div>
                    <div className="login-register-footer"></div>  
                </div>
            </div>
        </div>
    </div>
);
};

export default ForgotPassword;