import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Header from "../Components/Header/Header";
import { useAuthContext } from "../context/AuthContext";
import { API } from '../constant';
import LoadingImg from "../Media/loading.gif";

const Subscription = ({onDataFromChild} ) =>{
    const [subscription, setSubscription] = useState();
    const [userId, setUserId] =useState();
    const { user, isLoading, setUser } = useAuthContext();
    const [loading, setLoading] = useState(false);
    let username;
    const [data, setData] = useState(null);
    let email;
    let userid;
    if(user!=undefined){
        // console.log(user)
        username = user.username;
        email = user.email;
        userid = user.id;
        // setUserId(user.id);
    }
    // console.log(user);

    const sendDataToParent = () => {
        onDataFromChild("My Subscriptions");
        };
    useEffect(() => {

        const fetchSubscription = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API}/subscriptions?filters[userId][$eq]=${userid}`);
            console.log(response);
            const userSubscription = response.data.data.map((item)=>{
            const dateNext = new Date(item.attributes.nextPaymentDate);
            return (
                <tr id={item.id} className="subscription-item order-item">
                    
                    <td className="subscription-id">#<span>{item.id}</span></td>
                    <td className="subscription-status"><span> {item.attributes.status}</span></td>
                    <td className="subscription-next-payment-date"><span>{`${dateNext.getDate()}-${dateNext.getMonth() + 1}-${dateNext.getFullYear()}`}</span></td>
                    <td><Link to ={`/subscriptions/${item.id}`} ><button onDataFromSubscription={sendDataToParent} className="theme-btn">View</button> </Link> </td>    
                </tr>   
            );
                
        });
        setSubscription(userSubscription);
        setLoading(false);
        } catch (error) {
        console.error(error);
        }
        };
        
        fetchSubscription();
        }, [user]);
    
    return(         
<       div className="my-subscriptions subscription-table">
{loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
                <div className="table-responsive">
                <table>
                <thead>
                <tr class="order-head order-item subsription-head">
                    <th className="subscription-id"><span>Subscription</span></th>
                    <th className="subscription-status"><span>Status</span></th>
                    <th className="subscription-next-payment-date"><span>Next Payment</span></th>
                    <th className="subscription-amount"><span>Link</span></th>
                </tr>
                </thead>
                <tbody>
                {subscription?subscription:''}
                </tbody>
                </table>
                </div>
        </div>         
    );
}
export default Subscription;