import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Header from "../Components/Header/Header";
import { useAuthContext } from "../context/AuthContext";
import { API } from '../constant';
import { getToken } from '../helpers';
import LoadingImg from "../Media/loading.gif";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ChargesTable from './chargesTable';
const SubscriptionDetails = () =>{
    const [subscription, setSubscription] = useState();
    const [subscriptionId, setSubscriptionId] = useState();
    const [loading, setLoading] = useState(true);
    const [subscriptionState, setSubscriptionState] = useState("");
    const [screenMessage, setScreenMessage] = useState("");
    const [charges, setCharges] = useState([]);
    const { user, isLoading, setUser } = useAuthContext();
    const {id} = useParams();
    const myFile ="";
   
    useEffect(() => {

        const fetchSubscription = async () => {
        try {
        const responseData = await axios.get(`${API}/subscriptions/${id}?populate=charges`);
        const plan = await axios.get(`${API}/plans?filters[stripePlanId][$eq]=${responseData.data.data.attributes.stripePlanId}`);
        const subscriptionInfo = responseData.data.data.attributes;
        console.log(subscriptionInfo);
        const dateNext = new Date(subscriptionInfo.nextPaymentDate);
        const dateStart = new Date(subscriptionInfo.startDate);
        const dateEnd = new Date(subscriptionInfo.endDate);
        setSubscriptionState(subscriptionInfo.status);
        setCharges(subscriptionInfo.charges);
        setSubscription (
            <div className="subscription-item">
                <div className="subscription-plan-amount"><span>Amount :</span> ${plan.data.data[0].attributes.amount}</div>
                <div className="subscription-plan-Name"><span>Plan Name :</span>{plan.data.data[0].attributes.name}</div>
                <div className="subscription-plan-interval"><span>Payment Interval :</span>{plan.data.data[0].attributes.interval}</div>
                <div className="subscription-status"><span>Status :</span> <span id="updataStatus">{subscriptionInfo.status}</span></div>
                <div className="subscription-startdate"><span>Start Date :</span> <span>{`${dateStart.getDate()}-${dateStart.getMonth() + 1}-${dateStart.getFullYear()} ${dateStart.getHours()}:${dateStart.getMinutes()}:${dateStart.getSeconds()}`}</span></div>
                <div className="subscription-nextdate"><span>Next Payment Date:</span> <span>{`${dateNext.getDate()}-${dateNext.getMonth() + 1}-${dateNext.getFullYear()} ${dateNext.getHours()}:${dateNext.getMinutes()}:${dateNext.getSeconds()}`}</span></div>
            </div>
        );
        setSubscriptionId(subscriptionInfo.subscriptionId);
        setLoading(false);
        } catch (error) {
        setLoading(false);
        console.error(error);
        } finally{
            setLoading(false);
        }
        };
        
        fetchSubscription();
        }, [id]);

    const cancelSubscriptionHandler = async() =>{
        setLoading(true);
        try {
       console.log(subscriptionId);
        const response = await axios.post(`${API}/stripe/cancel-subscription`, {
            subscriptionId: subscriptionId,
        },
        {
            headers: {
            Authorization: `Bearer ${getToken()}`,
            },
        });
        const uStatus = document.getElementById("updataStatus");
        uStatus.innerHTML="Canceled";  
        
        if(response.data.subscription.status==="canceled"){
            const emailResponse = await fetch(`${API}/email/cancel-subscription`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify({ userId:user.id }),
            });
    
            const data = await emailResponse.json();
            setScreenMessage(response.message);
            setSubscriptionState("");
            setLoading(false);
        }  
        // console.log(response);
        // setMessage("Subscription Cancelled successfuly");
        //window.location.replace("/");

        }
        catch (error) {
                console.log(error.message);
                setScreenMessage(error.message);
                setLoading(false);
                // setMessage(error.message);
            }
    }

    const showConfirmDialog = (e) => {
        const paymentMethodId = e.target.getAttribute("methodid");
        confirmAlert({
          title: 'Confirm to cancel',
          message: <p>Are you sure you want to cancel this subscription?</p>,
            buttons: [
            {
            label: 'Yes',
            onClick: () => cancelSubscriptionHandler()
            },
            {
            label: 'No',
            onClick: () => {}
            }
            ]
          });
        };
    
    return(
            <div className="common-bg white-bg">
                <div className="container">
                {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
                    <Header title="My Subscription"/>
                    <div className="content-wrap">
                     <div className="my-subscriptions details">
                        <div className="items">
                        <div className="head-title">Subscription Details</div>
                        {subscription}
                        <div class="head-title">Previous Charges</div>
                        <ChargesTable charges={charges}/>
                        {screenMessage}
                        <div className="footer links">
                            {subscriptionState==="active"?<button onClick={showConfirmDialog} className="theme-btn">Cancel Subscription</button>:""}
                            <Link to="/dashboard"><button  className="theme-btn">Go Back</button></Link>
                        </div>
                    </div>  
                    </div>
                    </div>
                </div>
            </div>
    );
}
export default SubscriptionDetails;