import {BASE_URL} from "./../../constant";
const CarouselItem = (props) =>{
    

    const itemImage = props.skillItem.image.data;
    
    return(
        <div className="carousel-item">
            <div className="carousel-item-inner">
                <div class="carousel-item-title">{props.skillItem.title}</div>
                <div class="carousel-item-text">{props.skillItem.description}</div>
              
            </div>
        </div>
        
    );
    
}
export default CarouselItem;