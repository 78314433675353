import React from 'react';
import "./card.css";

const ChargesTable = ({charges}) => {
    console.log(charges);
return (
<div className="subscription-table">
<div className="table-responsive">
<table >
<thead>
<tr className="order-head order-item">
<th>Order Id</th>
<th>Description</th>
<th>Date</th>
<th>Status</th>
<th>Amount</th>
<th>Receipt</th>
</tr>
</thead>
<tbody>
{charges.map(charge => (
<tr key={charge.id}>
<td>#{charge.id}</td>
<td>{charge.description}</td>
<td>{`${new Date(charge.date ).getDate()}-${new Date(charge.date ).getMonth() + 1}-${new Date(charge.date ).getFullYear()}`}</td>
<td>{charge.status}</td>
<td>${charge.amount}</td>
<td><a href="#" target="_blank" rel="noopener noreferrer">View Receipt</a></td>
</tr>
))}
</tbody>
</table>
</div>
</div>
);
};

export default ChargesTable;