import Header from "../Components/Header/Header";
import BreathingBox from "../Components/BreathBox/BreathingBox";
const Breath = () =>{
    return(
        <div className="common-bg">
            <div className="container">
                <Header title ="" />
                    <BreathingBox />
            </div>
        </div>
    );
}  
export default Breath;