import { useState, useEffect } from "react";
import "./Skills.css";
import SkillItem from "./SkillItem";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";


const RecommendedSkills = (Props) => {
    
        const [skillItems, setSkillItems] = useState([]);
        const [formItems, setFormItems] = useState([]);
        const { user, isLoading, setUser } = useAuthContext();

        useEffect(() => {
            fetch(`${API}/skills?populate=*`)
              .then((resSkill) => {
                return resSkill.json();
              })
              .then((dataSkills) => {
                // console.log("MyData:" + dataSkills);
                setSkillItems(dataSkills.data);
                
              });

            //   fetch(`${API}/forms?populate[all_stresses][populate]=*`)
              fetch(`${API}/forms?populate[all_stresses][populate]=*&sort=createdAt:desc&pagination[limit]=1`)
              .then((resForm) => {
                return resForm.json();
              })
              .then((dataForm) => {
                console.log(dataForm.data[0]);
                // console.log(dataForm.data[dataForm.data.length -1])
                if(dataForm.data.length<=0)
                    console.log( "No data found")
                else {
              
                // console.log(dataForm.data)
                // const formSkillTyp = dataForm.data[dataForm.data.length -1].attributes.all_stresses;
                const formSkillTyp = dataForm.data[0].attributes.all_stresses;
                // console.log(formSkillTyp)
                let mindex = 0;
                let formSkillTypArray =[];
                // console.log(formSkillTyp);
                Object.keys(formSkillTyp).map((componentKey) =>{
                    if(componentKey !='id'){
                        const tempItem = formSkillTyp[componentKey];
                        // console.log(tempItem);
                        Object.keys(tempItem).map((key) =>{
                            if(tempItem[key] != null && tempItem[key] != false && key !='id'){
                                // console.log(dataForm.data[len][key])
                                formSkillTypArray[mindex] = key;
                                mindex++;
                                // console.log(key);
                            }
                        });   
                    }
                
                });
            
                // console.log(formSkillTypArray);
                setFormItems(formSkillTypArray);   
            }
              });
          }, []);
        //   console.log(skillItems);
        // console.log(formItems);

    return(
        <div className="skills">
                
                <div className="skills-inner">
                        {
                        skillItems.map((item)=>{  
                            let x=false;
                                    // console.log(item)
                                    if(item.attributes.skill_types.data.length > 0){
                                        item.attributes.skill_types.data.map((childitem, index)=>{
                                            // console.log(childitem.attributes.Category)
                                            // console.log(formItems)
                                            if(formItems.includes(childitem.attributes.Category)){  
                                                x =true;   
                                                // console.log(childitem)
                                                // return <SkillItem SklItem={item.attributes} itemId={item.id}/>

                                            }
                                        })
                                    }
                            if (x==true){
                                return <SkillItem SklItem={item.attributes} itemId={item.id}/>
                            }
                        })}
                </div>
            </div>
    );
}
export default RecommendedSkills;