import "./User.css";
import { Link } from 'react-router-dom';
const User =(props)=>{
    return(
        <div className="content-wrap qc">
            <div className="content-wrap-inner white-bg">
            <div className="user-info">
                <div className="user-name">{props.user[0].userName}</div>
                <div className="user-email">{props.user[0].emailId}</div>
            </div>
            <div className="user-settings">
                <div className="user-title">Account Settings</div>
                <ul className="user-details">
                    <li><Link to="/Profile">Edit Profile</Link></li>
                    <li><Link to="/Faq">FAQ</Link></li>
                </ul>
            </div>
            </div>
        </div> 
    );
}
export default User;