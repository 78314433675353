import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from "../Components/Header/Header";
import './card.css';
import { Link } from 'react-router-dom';

const OrderThankYou = () => {
const location = useLocation();
const { orderDetails } = location.state || {};

if (!orderDetails) {
return (
<div className="thank-you-page">
<div className="common-bg">
<div className="container">
<Header />
<div className="content-wrap white-bg">
<p>No order details available.</p>
</div>
</div>
</div>
</div>
);
}

const {
customerName,
customerEmail,
customerAddress,
orderSummary,
paymentDetails,
} = orderDetails;

return (
<div className="thank-you-page">
    <div className="common-bg">
        <div className="container">
        <Header />
        <div className="padding30"></div>
        <div className="content-wrap white-bg">
            <div className="thank-you-header">
                <h1>Thank You for Your Order!</h1>
                <p>We appreciate your business. Here are the details of your order:</p>
            </div>

            <div className="customer-info card">
                <h2>Customer Information</h2>
                <p><strong>Name:</strong> {customerName}</p>
                <p><strong>Email:</strong> {customerEmail}</p>
                <p><strong>Address:</strong> {customerAddress.line1}, {customerAddress.city}, {customerAddress.state}, {customerAddress.postal_code}, {customerAddress.country}</p>
            </div>

            <div className="order-summary card">
                <h2>Order Summary</h2>
                <ul>
                {orderSummary.map((item, index) => (
                    <li key={index}>
                        <p><strong>Product:</strong> {item.productName}</p>
                        <p><strong>Quantity:</strong> {item.quantity}</p>
                        <p><strong>Price:</strong> ${item.price}</p>
                    </li>
                ))}
                </ul>
            </div>

            <div className="payment-details card">
                <h2>Payment Details</h2>
                <p><strong>Payment Method:</strong> {paymentDetails.method}</p>
                <p><strong>Amount Paid:</strong> ${paymentDetails.amount}</p>
                <p><strong>Transaction ID:</strong> {paymentDetails.transactionId.id}</p>
            </div>
            <div className="footer-details">
            <p>If you have any questions about your order, please contact our support team.</p>
            <p><a href="mailto:info@copeahead.com">info@copeahead.com</a></p>
            <div className="thankyou-links"><Link to="/"><button className="theme-btn">Go to home</button></Link> <Link to="/dashboard"><button className="theme-btn">Go to dashboard</button></Link></div>
            </div>
            

            </div>
        </div>
    </div>
    <div className="footer-main">
        <div className="mask-with-ellips">
            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/login_register_bottom_1a090d4194.svg" alt="bg" />
        </div>
        <div className="login-register-footer"></div>
    </div>
</div>
);
};

export default OrderThankYou;