import Header from "../Components/Header/Header";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../constant";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";
import RecommendedSkills from "../Components/Skills/RecommendedSkills";
import { useNavigate } from "react-router-dom";
import "./Form.css"

const Form = () =>{
  
    const [error, setError] = useState("");
    const { user, isLoading, setUser } = useAuthContext();
    const [fielddata, setfielddata] = useState([]);
    const [stepIndex, setStepIndex] = useState(0);
    const [allFields, setAllFields] = useState([]);
    const [allComponentName, setallComponentName] = useState([]);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {   
      fetch(`${API}/content-type-builder/content-types/api::form.form`)
      .then((resForm) => {
        return resForm.json();
      })
      .then((dataForm) => {
        const fieldGroupObj  = dataForm.data.schema.attributes;
        let lindex = 0;
        let component =[];
        Object.keys(fieldGroupObj).map((keyName, i) =>{
          if(fieldGroupObj[keyName].type === "component"){
            component[lindex] = fieldGroupObj[keyName].component;
            lindex++;  
          }
        });

      // setFitem(component) 
      fetch(`${API}/content-type-builder/components/${component}`)
      .then((resFormComponent) => {
        return resFormComponent.json();
      })
      .then((dataFormComponent) => {  
        // console.log(dataFormComponent)
        const fieldObj  = dataFormComponent.data.schema.attributes;
        const fieldarray = Object.keys(fieldObj).map((key) =>fieldObj[key].component);
        const fieldObjName = Object.keys(fieldObj).map((key) =>key);
        // setFitem(fieldarray);
        setallComponentName(fieldObjName);

        let fieldItems=[];
        fieldarray.map((citem, index)=>{
          fetch(`${API}/content-type-builder/components/${citem}`)
          .then((resFormField) => {
            return resFormField.json();
          })
          .then((dataFormField) => {  
            // console.log(stepIndex)
            const fieldObj  = dataFormField.data.schema.attributes;
            const fieldarray1 = Object.keys(fieldObj).map((key) =>key);
            fieldItems = [...fieldItems, fieldarray1];
            setfielddata(fieldItems);
            // console.log(x)
          });   
        })
      });  
      }); 
     
  },[]);   

    const prev = (()=> {
      if(0 < stepIndex){
      setStepIndex(step => step < fielddata.length ? step - 1 : step);
    }  
    })

    const next = () => {
      setAllFields({...allFields,[allComponentName[stepIndex]]:checkboxValues})
      setStepIndex(step => step < fielddata.length ? step + 1 : step);   
    }

    const handleOnChange = (event) => {
      const {name, value, checked} = event.target; 
      setCheckboxValues({
        ...checkboxValues,
        [name]: checked ? true : value
      });
    };
    // console.log(checkboxValues)
    const onFinish = async (values) => {
      values.preventDefault();
      try {
          
          // console.log(allFields);
          const putResponse = await fetch(`${API}/forms`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer: ${getToken()}`,
            }, 
            body: JSON.stringify(
              {
                "data": {
                  "users_permissions_user": user.id,
                  "form_category": "",
                  "all_stresses": allFields
                  
                }
              }
            ),
          });
          const responseUserData = await putResponse.json();
          // console.log(responseUserData);
          navigate("/Recommended", { replace: true });
          // return <RecommendedSkills />

      } catch (error) {
          setError("Something went wrong!");
      }
    }
    // console.log(allFields)


  return(
      <div className="common-bg">
          <div className="container">
              <Header title ="Skills" />
              <div className="content-wrap">
              <div className="form-area-inner">
                <div className="form-header">
                    <h4>What are you feeling today?</h4>
                    <p>Select as many as you want</p> 
                </div> 
                <div className="form-fields-area">
                    <form onSubmit={onFinish} id="create-course-form">
                        
                          {   fielddata.map((item, index)=>{
                            const xfield = item.map((itemc)=>{
                              return (<div className="col-2 checkbox">
                                  <label>
                                      <input defaultChecked={false} hidden={allComponentName[stepIndex] === allComponentName[index]? false: true }  comp={allComponentName[index]} type="Checkbox" name={itemc} value="false" onChange={handleOnChange} /> {itemc}
                                  </label>
                                </div> );
                            })
                              return (
                                <div className ={`${allComponentName[index]} ${allComponentName[stepIndex] === allComponentName[index]? "": "hidden-field" }`}>
                                  <h3>{allComponentName[index].replace(/[_,-]/g,' ')}</h3>
                                  <div className="row check-wrap" > 
                                    {xfield}
                                  </div>
                                </div>
                                )
                            })
                          }                         
                      <div className="form-footer">
                      <button className="theme-btn" type="button" onClick={prev}>Back</button> 
                      <button className="theme-btn" type={stepIndex <= (fielddata.length - 1) ? "button":"submit"} onClick={next}>{stepIndex <= (fielddata.length -2) ? "Next":"Submit"}</button>
                      </div>   
                    </form>
                </div>
            </div>
            </div>
          </div>
      </div>
  );
}
export default Form;