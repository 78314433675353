import Header from "../Components/Header/Header";
import { useAuthContext } from "../context/AuthContext";
import RecommendedSkills from "../Components/Skills/RecommendedSkills";
import { useState, useEffect } from "react";
import { API } from "../constant";
import Featured from "../Components/Skills/Featured";

const PaymentCancel = () =>{
    const { user, isLoading, setUser } = useAuthContext();
    const [formItems, setFormItems] = useState([]);
    // console.log(user)
    let userName;
    if(user !=undefined)
    userName = user.username;

    useEffect(() => {

          fetch(`${API}/forms?populate[all_stresses][populate]=*`)
          .then((resForm) => {
            return resForm.json();
          })
          .then((dataForm) => {
            setFormItems(dataForm.data);
          
            console.log(dataForm.data)
            if(dataForm.data.length >0 ){
                const formSkillTyp = dataForm.data[dataForm.data.length -1].attributes.all_stresses;
                // console.log(formSkillTyp)
                let mindex = 0;
                let formSkillTypArray =[];
                // console.log(formSkillTyp);
                Object.keys(formSkillTyp).map((componentKey) =>{
                    if(componentKey !='id'){
                        const tempItem = formSkillTyp[componentKey];
                        // console.log(tempItem);
                        Object.keys(tempItem).map((key) =>{
                            if(tempItem[key] != null && tempItem[key] != false && key !='id'){
                                // console.log(dataForm.data[len][key])
                                formSkillTypArray[mindex] = key;
                                mindex++;
                                // console.log(key);
                            }
                        });   
                    }
                    
                });
                setFormItems(formSkillTypArray);  
            }
            // console.log(formSkillTypArray); 
          });
      }, []);
    //   console.log(formItems);
      const showSkill = ()=>{
        if(formItems.length > 0){
        return <RecommendedSkills headTitle="Today's recommended skills"/>
        }else{
        return <Featured headTitle="Today's recommended skills"/>
        }
    
    }
// console.log(showSkill)
    return(
        <div className="common-bg">
            <div className="container">
                <Header title ={`Hello ${userName}`} />
                <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="banner-content">
                            <h4 className="title">Sorry!!! <br/>You Feel That way</h4>
                            <p className="description">Lets take a few breathes and try these coping mechanisms</p>
                        </div>
                        <div className="banner-right-img">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                        </div>
                    </div>
                    
                </div>
                <div className="content-wrap qc">
                {/* {showSkill()} */}
                    
                </div>
                
            </div>
        </div>
    );
}
export default PaymentCancel;