import Header from "../Components/Header/Header";
import { useAuthContext } from "../context/AuthContext";
import RecommendedSkills from "../Components/Skills/RecommendedSkills";
// import { useParams } from "react-router-dom";

const Recommendations = () =>{
    // const param  = useParams();
    const { user, isLoading, setUser } = useAuthContext();
    console.log(user)
    let userName;
    if(user !=undefined)
    userName = user.username;

    return(
        <div className="common-bg">
            <div className="container">
                <Header title ="Quick Cope" />
                <div className="banner-box">
                    <img src="http://localhost:1337/uploads/quick_cope_img_810b24c914.svg" alt="qc-banner"/>
                    <div className="banner-wrap rc">
                        <div className="banner-content">
                            { <h4 className="title">Personalised Recommended Skills for <span>{userName}</span></h4> }
                        </div>
                        <div className="banner-right-img">
                            <img src="http://localhost:1337/uploads/recommended_46aa82e28b.svg" alt="banner-img"/>
                        </div>
                    </div>
                    
                </div>
                <div className="content-wrap qc">
                    <RecommendedSkills headTitle="Recommendations"/>
                </div>
                
            </div>
        </div>
    );
}
export default Recommendations;