import Header from "../Components/Header/Header";
import Featured from "../Components/Skills/Featured";
import { useState } from "react";
import LoadingImg from "../../src/Media/loading.gif";

const QuickCope = () =>{
    const [loading, setLoading] = useState(false);
    return(
        <div className="common-bg skills">
            
            <div className="container">
            {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
                <Header title ="Quick Cope" />
                {()=>{setLoading(true)}}
                <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="banner-content">
                            <h4 className="title">Sorry!!! <br/>You Feel That way</h4>
                            <p className="description">Lets take a few breathes and try these coping mechanisms</p>
                        </div>
                        <div className="banner-right-img">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                        </div>
                    </div>
                    
                </div>
                <div className="content-wrap qc ">
                <div className="content-wrap-inner white-bg">
                    <Featured headTitle="" desc=""/>
                    {()=>{setLoading(false)}}
                </div>
                </div> 
            </div>
        </div>
    );
}
export default QuickCope;