import StripeSubscription from "../Components/Stripe/StripeSubscription";
import Header from "../Components/Header/Header";
const GoPremium = () =>{
    return(
        <div className="common-bg">
            <div className="container">
            <Header/>
            <div className="padding30"></div>
                <div class="content-wrap white-bg">
                    <div className="content-wrap-inner subscription-form">
                    <StripeSubscription />
                    </div>
                </div>
                <div className="footer-main">
                        <div className="mask-with-ellips">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/login_register_bottom_1a090d4194.svg" alt="bg"/>
                        </div>
                        <div className="login-register-footer"></div>  
                    </div>
            </div>
        </div>
    );
}
export default GoPremium;