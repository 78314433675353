import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { AUTH_TOKEN } from '../../constant';
import { API } from '../../constant';
import { useNavigate } from 'react-router-dom';
import LoadingImg from "../../Media/loading.gif";


// const stripePromise = loadStripe('pk_test_GhEdsltgdfRISZ3HZAR7xuE3');

const CheckoutForm = ({ plan, userId }) => {
const { user, isLoading, setUser } = useAuthContext(); 
const [loading, setLoading] = useState(false);  
const [message, setMessage] = useState('');
const stripe = useStripe();
const elements = useElements();
const [error, setError] = useState(null);
const [customerName, setCustomerName] = useState('');
const [customerEmail, setCustomerEmail] = useState('');
const [customerIds, setCustomerId] = useState('');
const [customerAddress, setCustomerAddress] = useState({
    line1: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
});

// State to trigger re-render
const [paymentSuccess, setPaymentSuccess] = useState(false);

const navigate = useNavigate();

const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    console.log(`Customer Id: ${customerIds}`);
    try {
            const response = await axios.post(`${API}/stripe/create-subscription`, {
                planId: plan.stripePlanId,
                customerName,
                customerEmail,
                customerAddress,
                customerIds
            },
            {
                headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                },
            });
        
            const { clientSecret, customerId } = response.data;
            
            // Retrieve existing payment methods

            const card = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            
            const paymentMethodsResponse = await axios.post(`${API}/stripe/payment-methods`, {
                customerId: customerId,
            }, 
            {
                headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                },  
            });

            const existingPaymentMethod = paymentMethodsResponse.data.data.find(
            method => method.card.fingerprint === card.paymentMethod.card.fingerprint
            );
 
            console.log(`Existing Card Data: ${JSON.stringify(paymentMethodsResponse, null, 2)}`);

            let paymentMethodId;
            let paymentIntent;
            let error;

            console.log(`Client Secret: ${clientSecret}`);
            if (existingPaymentMethod) {
                paymentMethodId = existingPaymentMethod.id;
                console.log('Card already exists, using existing payment method.');

                // Confirm the payment using the existing payment method
                const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodId,
                });

                error = result.error;
                paymentIntent = result.paymentIntent;

                if (error) {
                setError(error.message);
                setLoading(false);
                return;
                }
                
            }else {
                const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                card: cardElement,
                },
                });

                error = result.error;
                paymentIntent = result.paymentIntent;

                if (error) {
                    setError(error.message);
                    setLoading(false);
                    return;
                }

                paymentMethodId = result.paymentIntent.payment_method;
                
            }

            console.log('Payment Intent:', JSON.stringify(paymentIntent, null, 2));

                await axios.post(`${API}/stripe/attach-payment-method`, {
                customerId: customerId,
                paymentMethodId: paymentMethodId,
                },
                {
                headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                },
                });

                console.log( `Customer Id: ${customerId}`);

                setLoading(false);
                setMessage("You have made payment successfully");
                setPaymentSuccess(true);
                
                // Prepare order details
                const orderDetails = {
                    customerName,
                    customerEmail,
                    customerAddress,
                    orderSummary: [ 
                    { productName: plan.name, quantity: 1, price: plan.amount },
                    ],
                    paymentDetails: {
                    method: 'Credit Card',
                    amount: plan.amount,
                    transactionId: paymentIntent,
                    },
                    };

                    if(paymentIntent){
                        const emailResponse = await fetch(`${API}/email/order-email`, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${AUTH_TOKEN}`,
                            },
                            body: JSON.stringify({ orderDetails }),
                        });
                
                        // const data = await emailResponse.json();
                        // setScreenMessage(response.message);
                        setLoading(false);
                    }  
                    
                    // Navigate to Thank You page with order details
                    navigate('/my-account/order-thank-you', { state: { orderDetails } });
        
        } 
        catch (error) 
        {
            setError(error.message);
            setMessage(error.message);
            setLoading(false);
        }
    };

useState(()=>{
    
    // console.log(isLoading);
    // console.log(user);
    if(isLoading)
    return <div>Loading</div>
    setCustomerName(user.username);
    setCustomerEmail(user.email);
    setCustomerId(user.customerId);
    setCustomerAddress({
        line1:user.address,
        city: user.city,
        state: user.state,
        postal_code: user.postalCode,
        country: user.country,
    });
    
},[]);

useEffect(() => {
    if (paymentSuccess) {
    // Perform any additional actions needed after payment success
    console.log('Payment was successful, state has been refreshed.');
    }
}, [paymentSuccess]);
    

return (
<div className="theme-form">
{loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
    <div className="register-form">
        <form onSubmit={handleSubmit}>
        <input
        type="text"
        placeholder="Name"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
        required
        />
        <input
        type="email"
        placeholder="Email"
        value={customerEmail}
        onChange={(e) => setCustomerEmail(e.target.value)}
        required
        />
        <input
        type="text"
        placeholder="Address Line 1"
        value={customerAddress.line1}
        onChange={(e) => setCustomerAddress({ ...customerAddress, line1: e.target.value })}
        required
        />
        <input
        type="text"
        placeholder="City"
        value={customerAddress.city}
        onChange={(e) => setCustomerAddress({ ...customerAddress, city: e.target.value })}
        required
        />
        <input
        type="text"
        placeholder="State"
        value={customerAddress.state}
        onChange={(e) => setCustomerAddress({ ...customerAddress, state: e.target.value })}
        required
        />
        <input
        type="text"
        maxlength="5"
        placeholder="Zip Code"
        value={customerAddress.postal_code}
        onChange={(e) => setCustomerAddress({ ...customerAddress, postal_code: e.target.value })}
        required
        />
        <input
        type="text"
        placeholder="Country"
        value={customerAddress.country}
        onChange={(e) => setCustomerAddress({ ...customerAddress, country: e.target.value })}
        required
        />
        <div className="payment-details">
            <p className="heading">Payment details</p>
            <CardElement />
        </div>
        <button className="theme-btn" type="submit" disabled={!stripe}>Subscribe</button>
        <div className="message success">{message}</div>
        </form>
    </div>
</div>

);
};

export default CheckoutForm