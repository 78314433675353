import { useState, useEffect } from "react";
import "./Skills.css";
import SkillItem from "./SkillItem";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";

const FilterSkills = (Props) => {
    
        const [skillItems, setSkillItems] = useState([]);
        const [formItems, setFormItems] = useState([]);
        const [selectedItems, setselectedItems] = useState([]);
        const { user, isLoading, setUser } = useAuthContext();

        useEffect(() => {
            fetch(`${API}/skills?populate=*`)
              .then((resSkill) => {
                return resSkill.json();
              })
              .then((dataSkills) => {
                // console.log(dataSkills.data)
                const allItems = dataSkills.data.map((item=>{
                    return item.attributes.skill_types.data
                }))
                setSkillItems(dataSkills.data);
                let catIndex=0;
                let tempCat=[]
                allItems.map((item, index)=>{
                    if(item.length > 0){
                    item.map((catItem)=>{
                        // return catItem.attributes.Category;
                        tempCat[catIndex] = catItem.attributes.Category;
                        catIndex++;
                     })     
                    }
                    
                })
                // console.log(tempCat);
                
                setFormItems(tempCat);
                setselectedItems(tempCat);

                
              });
          }, []);
        //   console.log(skillItems);
        const onchangeSkills = (event)=>{
            if(event.target.value === "Select Activities")
            setselectedItems(formItems);
            else
            setselectedItems(event.target.value);
            // console.log(event.target.value);
          }

    return(
        <div className="skills filter">
                <div className="head-title">
                    <span>{Props.headTitle}</span>
                    <span className="filter">
                        <form>
                            <div className="select-wrap">
                                <select name="skill_filter" onChange={onchangeSkills}>
                                    <option value="Select Activities">Select Activities</option>;   
                                {
                                    formItems.map((item, i)=>{
                                        return <option   key={i} value={item}>{item}</option>;
                                    })
                                }
                            </select>
                            </div>
                        </form>
                    </span>
                </div>
                <div className="description"></div>
                <div className="skills-inner">
                    
                        {skillItems.map((item, i)=>{  
                            let x=false;
                                    // console.log(item)
                                    if(item.attributes.skill_types.data.length > 0){
                                        item.attributes.skill_types.data.map((childitem, index)=>{
                                            
                                            if(selectedItems.includes(childitem.attributes.Category)){  
                                                x =true;               
                                            }
                                        })
                                    }
                            if (x==true){
                                return (<SkillItem key={i} SklItem={item.attributes} itemId={item.id}/>)
                            }
                        })}
                </div>
            </div>
    );
}
export default FilterSkills;