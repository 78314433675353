import React, { useEffect, useState } from 'react';
import { API } from '../constant';
import { getToken } from "../helpers";
import { useAuthContext } from "../context/AuthContext";
import { STRIPE_KEY } from '../constant';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import AddPaymentMethodForm from './addPaymentMethod';
import "./card.css";
import LoadingImg from "../Media/loading.gif";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';

const stripePromise = loadStripe(STRIPE_KEY);

const PaymentMethod = () =>{
    const { user, isLoading, setUser } = useAuthContext();
    const [ formActive, setformActive ] = useState("");
    const [method, setMethod] =useState();
    const [cardLength, setCardLength] =useState(0);
    const [stripeCustomerId, setstripeCustomerId] =useState();
    const [loading, setLoading] = useState(false);
    const [paymentForm, setpaymentForm] = useState("");

    // console.log(user);

    const deleteMethod = async(paymentMethodId)=>{
      setLoading(true);
      // const paymentMethodId = e.target.getAttribute("methodid");
      console.log(paymentMethodId);
      try {
        const response = await fetch(`${API}/stripe/delete-method/${user.id}/${paymentMethodId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // set the auth token to the user's jwt
            Authorization: `Bearer: ${getToken()}`,
          },
          
        });
        const responseData = await response.json();
        // console.log(user);
        if(response.ok)
          fetchMethod();
        setLoading(false);
       
      }catch (error) {
        console.log(error.message);
      }
      
    };

    const defaultCardHandler = async (e) =>{
      setLoading(true);
      const customerId = e.target.getAttribute("custid");
      const paymentMethodId =e.target.getAttribute("methodid");
      // console.log(className);
      console.log(customerId);
      console.log(paymentMethodId);
      // console.log(e);
      try {
        const response = await fetch(`${API}/stripe/default-method`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // set the auth token to the user's jwt
            Authorization: `Bearer: ${getToken()}`,
          },
          body: JSON.stringify({ userId: user.id, customerId: customerId, paymentMethodId: paymentMethodId}),
        });
        const responseData = await response.json();
        // console.log(responseData);
        if(response.ok)
          fetchMethod();
        setLoading(false);
          // refreshMethod();
      }catch (error) {
        console.log(error.message);
      }
    };

    const fetchMethod = async () =>{
      setLoading(true);
      try {
        const response = await fetch(`${API}/users/${user.id}?populate=paymentMethods`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // set the auth token to the user's jwt
            Authorization: `Bearer: ${getToken()}`,
          },
        });
        const responseData = await response.json();
        // console.log(responseData);
    // setConfirmDel(responseData);
    // setCardLength(responseData.paymentMethods.length);

        const cards = responseData.paymentMethods.map((item)=>{
          return(
            <div className="card-wrap">
               <div className="credit-card">
               <button methodId={item.payment_method_id} onClick={showConfirmDialog} isDefault={`${item.is_default}`} className="close-btn theme-btn">X</button>
              <div className="credit-card__logo">{item.card_brand}</div>
              <div className="credit-card__number">XXXX XXXX XXXX {item.card_last4}</div>
              <div className="credit-card__details">
                <div className="credit-card__name">EXPIRY</div>
                <div className="credit-card__expiry">{item.card_exp_month}/{item.card_exp_year
                }</div>
              </div>
              <div onClick={defaultCardHandler} className="default-method"><span custId={responseData.customerId} methodId={item.payment_method_id} className={item.is_default?"dot default":"dot"}></span></div>
            </div>
            </div>
           
          )
        });
        
        setMethod(cards);
        setLoading(false);
      } catch (error) {
        console.error(Error);
      } 
    };
    useEffect(()=>{
      fetchMethod();
    },[]);
    
    // console.log(stripeCustomerId);
    const addMethodHandler = ()=>{
      setpaymentForm(<AddPaymentMethodForm/>);
      setformActive('active-form');
    }

    const closeHandler = ()=>{

      setformActive('');
      setpaymentForm('')
      fetchMethod();
    }

    const showConfirmDialog = (e) => {
      const paymentMethodId = e.target.getAttribute("methodid");
      const defaultCard = e.target.getAttribute("isdefault");
      // console.log(defaultCard)
      if(defaultCard == "false"){
      confirmAlert({
        title: 'Confirm to Delete',
        message: <p>Are you sure you want to delete this payment method?</p>,
          buttons: [
          {
          label: 'Yes',
          onClick: () => deleteMethod(paymentMethodId)
          },
          {
          label: 'No',
          onClick: () => {}
          }
          ]
        })
      }else {
        confirmAlert({
          title: 'Default Method!',
          message: <p>You can't delete this method as this is your default payment method.</p>,
            buttons: [
            {
            label: 'Cancel',
            onClick: () => {}
            }
            ]
          })}
          };
      
    // console.log("method" + JSON.stringify(method));
  return(
        <div className="method-section">
          {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
          <div onClick={addMethodHandler} className="create-method-link"><span>Add new method</span></div>
            <div className="method-wrap">
            <div className="method-inner">
              {method}
            </div>
          </div>
            <div className={`theme-form popup ${formActive}`}>
              <div className="form-wrap">
              <button onClick={closeHandler} className="close-btn theme-btn">X</button>
              <div className="head-title">Enter your card details</div>
                <Elements stripe={stripePromise}>
                    {paymentForm}
                    {/* <AddPaymentMethodForm/> */}
                  </Elements>
              </div>  
          </div>
        </div>   
  );
}
export default PaymentMethod;