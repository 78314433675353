import Header from "../Components/Header/Header";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../constant";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";
import RecommendedSkills from "../Components/Skills/RecommendedSkills";
import SkillItem from "../Components/Skills/SkillItem";
import { useNavigate } from "react-router-dom";
import "./Form.css";
const RCA = () =>{
  
    const [error, setError] = useState("");
    const { user, isLoading, setUser } = useAuthContext();
    const [fielddata, setfielddata] = useState([]);
    const [stepIndex, setStepIndex] = useState(0);
    const [allFields, setAllFields] = useState([]);
    const [allComponentName, setallComponentName] = useState([]);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [component, setComponent] = useState({
      common_stress:{value:'', count:0},
      relationship_stress:{value:'', count:0},
      performance_stress:{value:'', count:0},
      personal_anxiety:{value:'', count:0},
    })
    const navigate = useNavigate();

    let userName;
    let userId;
    if(user !=undefined){
    userName = user.username;
    userId = user.id;
    }

    const descSelectHandler =(e)=>{
      const element = e.target.closest(".rca").nextSibling;
      element.classList.add("active-form");
      e.preventDefault();
    }
    const closeButtonHandler = (e) =>{
      const element = e.target.closest(".form-popup");
      if(e.target.classList.contains('active-form'))
        e.target.classList.remove('active-form');
      else if(e.target.classList.contains('close-button') || e.target.tagName==="SPAN" )
      element.classList.remove("active-form");
    }
    const checkIcon = <div className="checked">
                        <span className="checkmark">
                          <div className="checkmark_stem"></div>
                          <div className="checkmark_kick"></div>
                        </span>
                      </div>
    const stepInfo = [
      {
        title:<h2 className="title">Root Cause Analysis</h2>,
        description:<p className="desc">Lorem ipsum dolor sit amet, adipisc ings  eiusmod  tempor incididunt Lorem is ipsum dolor sit amet, adipisc  <button onClick={descSelectHandler}>Tell us your feelings {(component.common_stress.count>0)?checkIcon:""}</button> amet sit amet. eiusmod  tempor.</p>
      },
      {
        title:<h2 className="title">Bad Relationship?</h2>,
        description:<p className="desc">Lorem ipsum dolor sit amet, adipisc ings  eiusmod  tempor incididunt Lorem is ipsum dolor sit amet, adipisc  <button onClick={descSelectHandler}>Relationship Stress {(component.relationship_stress.count>0)?checkIcon:""}</button> amet sit amet. eiusmod  tempor.</p>
      },
      {
        title:<h2 className="title">Performance Stress?</h2>,
        description:<p className="desc">Lorem ipsum dolor sit amet, adipisc ings  eiusmod  tempor incididunt Lorem is ipsum dolor sit amet, adipisc  <button onClick={descSelectHandler}>Performance Stress {(component.performance_stress.count>0)?checkIcon:""}</button> amet sit amet. eiusmod  tempor.</p>
      },
      {
        title:<h2 className="title">Facing Anxiety?</h2>,
        description:<p className="desc">Lorem ipsum dolor sit amet, adipisc ings  eiusmod  tempor incididunt Lorem is ipsum dolor sit amet, adipisc  <button onClick={descSelectHandler}>Personal Anxiety {(component.personal_anxiety.count>0)?checkIcon:""}</button> amet sit amet. eiusmod  tempor.</p>
      },
      {
        title:<h2 className="title">Personalised Recommended Skills for You <span>{userName}</span></h2>,
        description:<p className="desc">Based on our detailed analysis we found these task will help you to cope up with your anxiety. Lorem ipsum dolor sit amet. adipic.</p>
      },

    ]
    
    useEffect(() => {   
      fetch(`${API}/content-type-builder/content-types/api::form.form`)
      .then((resForm) => {
        return resForm.json();
      })
      .then((dataForm) => {
        // console.log(dataForm);
        const fieldGroupObj  = dataForm.data.schema.attributes;
        let lindex = 0;
        let component =[];
        Object.keys(fieldGroupObj).map((keyName, i) =>{
          if(fieldGroupObj[keyName].type === "component"){
            component[lindex] = fieldGroupObj[keyName].component;
            lindex++;  
          }
        });

      // setFitem(component) 
      fetch(`${API}/content-type-builder/components/${component}`)
      .then((resFormComponent) => {
        return resFormComponent.json();
      })
      .then((dataFormComponent) => {  
        // console.log(dataFormComponent)
        const fieldObj  = dataFormComponent.data.schema.attributes;
        const fieldarray = Object.keys(fieldObj).map((key) =>fieldObj[key].component);
        const fieldObjName = Object.keys(fieldObj).map((key) =>key);
        // setFitem(fieldarray);
        setallComponentName(fieldObjName);

        let fieldItems=[];
        fieldarray.map((citem, index)=>{
          fetch(`${API}/content-type-builder/components/${citem}`)
          .then((resFormField) => {
            return resFormField.json();
          })
          .then((dataFormField) => {  
            // console.log(dataFormField)
            const fieldObj  = dataFormField.data.schema.attributes;
            const fieldarray1 = Object.keys(fieldObj).map((key) =>key);
            fieldItems = [...fieldItems, fieldarray1];
            setfielddata(fieldItems);
            // console.log(x)
          });   
        })
      });  
      }); 
     
  },[]);   

    const prev = (()=> {
      if(0 < stepIndex){
      setStepIndex(step => step < fielddata.length ? step - 1 : step);
      const element = document.querySelectorAll(".rca-graphic-inner .step");
      // console.log(element)
      element.forEach((item, index)=>{
        if(index == stepIndex ){
          // console.log(item)
          item.classList.remove("active")
        }
          
      })
    }  
    })

    const next = () => {
      let newValues;
      const values = fielddata[stepIndex].map((item, index)=>{
        return checkboxValues;
        // if(checkboxValues.include(item))
          // return item;
      })
      // console.log(values)
      setAllFields({...allFields,[allComponentName[stepIndex]]:checkboxValues})
      setStepIndex(step => step < fielddata.length ? step + 1 : step); 
      const element = document.querySelectorAll(".rca-graphic-inner .step");
      // console.log(element)
      element.forEach((item, index)=>{
        if(index -1 <= stepIndex ){
          // console.log(item)
          item.classList.add("active")
        }
          
      })

    }

    const handleOnChange = (event) => {
      const {name, value, checked} = event.target; 
      const comp = event.target.getAttribute("comp");
      const cnt = component[comp].count;
      const comVal = {
        value:comp,
        count:checked?cnt + 1:cnt -1,
      }
      setComponent({...component, [comp]:comVal});
      
      setCheckboxValues({
        ...checkboxValues,
        [name]: checked ? true : value
      });
    };
    // console.log(component);
    // console.log(checkboxValues)
    const onFinish = async (values) => {
      values.preventDefault();

      // if(allFields===null)
        // setAllFields({common_stress:[{Sad:false}], performance_stress:[{Sad:false}], relationship_stress:[{Sad:false}],personal_anxiety:[{Sad:false}]})

      try {
          // console.log(allFields);
          const putResponse = await fetch(`${API}/forms`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer: ${getToken()}`,
            }, 
            body: JSON.stringify(
              {
                "data": {
                  "users_permissions_user": userId,
                  "form_category": "",
                  "all_stresses": allFields
                  
                }
              }
            ),
          });
          const responseUserData = await putResponse.json();
          // console.log(responseUserData);
          // navigate("/Recommended", { replace: true });
          // return <RecommendedSkills />

      } catch (error) {
          setError("Something went wrong!");
      }
    }
    // console.log(fielddata)
    // console.log(stepIndex);
    
    const preventNext = ()=>{
      if(stepIndex===0 && component.common_stress.count>0)
        return true;
      else if(stepIndex===1 && component.relationship_stress.count>0)
        return true;
      else if(stepIndex===2 && component.performance_stress.count>0)
        return true;
      else if(stepIndex===3 && component.personal_anxiety.count>0)
        return true;
      else
        return false;
    }

  return(
      <div className="common-bg skills">
          <div className="container">
              <Header title ="Skills" />
              <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="rca-graphic">
                          <div className="rca-graphic-inner">
                           <div className="step rca-step-1 active"><svg pth="1" xmlns="http://www.w3.org/2000/svg" width="77" height="72" viewBox="0 0 77 72" fill="none">
<path d="M61.1488 71.6355H7.33003C3.10958 71.6355 -0.222367 68.0539 0.126694 63.8701C1.84026 42.5388 7.93296 22.4437 17.548 4.50396C19.5154 0.795565 24.2753 -0.408869 27.7977 1.87322L73.0804 30.9382C76.1584 32.9033 77.2373 36.8336 75.6824 40.1617C72.0014 47.9588 69.4628 56.4216 68.3204 65.2964C67.8444 68.9097 64.7981 71.6355 61.1488 71.6355Z" fill="white"/>
</svg><img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/search_a2ccf66327.svg" width="56" alt=""/></div>
                           <div className="step rca-step-2"><svg pth="2" xmlns="http://www.w3.org/2000/svg" width="89" height="88" viewBox="0 0 89 88" fill="none">
<path d="M49.0309 86.2995L3.74822 57.2346C0.194149 54.9842 -0.662634 50.1347 1.90772 46.8067C16.9808 27.0919 36.782 11.1807 59.5979 0.752779C63.4376 -1.02218 67.9754 0.879561 69.4033 4.84153L87.7449 55.3962C89.0142 58.8193 87.491 62.6228 84.2543 64.271C74.3536 69.4057 65.5954 76.4105 58.4873 84.8415C56.139 87.6307 52.0772 88.2964 49.0309 86.2995Z" fill="white"/>
</svg><img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/face2_874050e017.svg" width="60" alt=""/></div>
                           <div className="step rca-step-3"><svg pth="3" xmlns="http://www.w3.org/2000/svg" width="92" height="72" viewBox="0 0 92 72" fill="none">
<path d="M46.0001 68.8891C39.6853 68.8891 33.5291 69.5864 27.5951 70.8859C24.0728 71.6783 20.5187 69.7132 19.2811 66.3217L0.939579 15.7353C-0.488396 11.8051 1.73289 7.46276 5.79469 6.35341C18.583 2.83518 32.0694 0.965134 46.0001 0.965134C59.9308 0.965134 73.4172 2.83518 86.2056 6.35341C90.2673 7.43106 92.4886 11.8051 91.0607 15.7353L72.7191 66.3217C71.4815 69.7132 67.9275 71.6783 64.4051 70.8859C58.4711 69.5864 52.3149 68.8891 46.0001 68.8891Z" fill="white"/>
</svg><img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/face3_b3d32e1b03.svg"  width="75" alt=""/></div>
                           <div className="step rca-step-4"><svg pth="4" xmlns="http://www.w3.org/2000/svg" width="89" height="88" viewBox="0 0 89 88" fill="none">
<path d="M1.25489 55.3962L19.5965 4.84153C21.0244 0.879561 25.5622 -1.02218 29.4019 0.752779C52.2177 11.1807 72.019 27.0919 87.0921 46.8067C89.6624 50.1347 88.8056 54.9842 85.2516 57.2346L39.9689 86.2995C36.9225 88.2964 32.8607 87.6307 30.5125 84.8415C23.4044 76.4105 14.6461 69.4057 4.7455 64.271C1.50876 62.6228 -0.0144161 58.8193 1.25489 55.3962Z" fill="white"/>
</svg><img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/face4_c446396aa9.svg" width="70px" alt=""/></div>
                           <div className="step rca-step-5"><svg pth="5" xmlns="http://www.w3.org/2000/svg" width="77" height="72" viewBox="0 0 77 72" fill="none">
<path d="M8.67906 65.2964C7.53668 56.4216 4.99806 47.9588 1.31706 40.1617C-0.23785 36.8336 0.841068 32.9033 3.91915 30.9382L49.2018 1.87322C52.7242 -0.408869 57.4841 0.795565 59.4515 4.50396C69.0665 22.4437 75.1592 42.5388 76.8728 63.8701C77.2218 68.0539 73.8899 71.6355 69.6695 71.6355H15.8507C12.2014 71.6355 9.15506 68.9097 8.67906 65.2964Z" fill="white"/>
</svg><img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/analysis_06473795b2.svg" width="85" alt=""/></div>
                          <div className="our-circle-logo"><div className="circle-logo"><svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
<path d="M43.9998 88.0317C19.8828 88.0317 0.367188 68.5388 0.367188 44.4501C0.367188 20.3296 19.8828 0.868439 43.9998 0.868439C68.1167 0.868439 87.6323 20.3296 87.6323 44.4501C87.6323 68.5388 68.1167 88.0317 43.9998 88.0317Z" fill="white"/>
</svg><img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/logo_icon_ea8cc666f3.png" alt="logo"/></div></div>
                        </div>
                        </div>
                    </div>
                    
                </div>
              <div className="content-wrap rca">
                <div className="content-wrap-inner white-bg">
              
                <div className="form-fields-area">
                   
                    <form onSubmit={onFinish} id="create-course-form">
                        {stepInfo.length === (stepIndex + 1)? stepInfo[stepIndex].title : ""}
                        {stepInfo.length === (stepIndex + 1)? stepInfo[stepIndex].description : ""}
                          {   fielddata.map((item, index)=>{

                            const xfield = item.map((itemc)=>{
                              return (<div className="col-2 checkbox">
                                  <label >
                                      <input defaultChecked={false} hidden={allComponentName[stepIndex] === allComponentName[index]? false: true }  comp={allComponentName[index]} type="Checkbox" name={itemc} value="false" onChange={handleOnChange} /> {itemc}
                                  </label>
                                </div> );
                            })
                              return (
                                
                                <div className ={`${allComponentName[index]} ${allComponentName[stepIndex] === allComponentName[index]? "": "hidden-field" }`}>
                                  <div className="rca">
                                  {stepInfo[stepIndex].title}
                                  {stepInfo[stepIndex].description}
                                </div>
                                  <div onClick={closeButtonHandler} className="form-popup">
                                    <div className="row check-wrap" > 
                                    <div onClick={closeButtonHandler} className="close-button"><span></span></div>
                                    <h3>{allComponentName[index].replace(/[_,-]/g,' ')}</h3>
                                      {xfield}
                                    </div>
                                  </div>
                                </div>
                                )
                            })
                          }                         
                      <div className={`form-footer ${stepInfo.length === (stepIndex + 1)?"hidden-field":""}`}>
                      <button className="theme-btn" type="button" onClick={prev}>Back</button> 
                      {preventNext()?<button className="theme-btn" type={stepIndex <= (fielddata.length - 1) ? "button":"submit"} onClick={next}>{stepIndex <= (fielddata.length -2) ? "Next":"Submit"}</button>:<button className="theme-btn disabled" type={stepIndex <= (fielddata.length - 1) ? "button":"submit"}>{stepIndex <= (fielddata.length -2) ? "Next":"Submit"}</button>}
                      </div>   
                    </form>
                </div>
            </div>
            </div>
            <div className="content-wrap rca-skills">
            <div className="form-area-inner">
            {/* <RecommendedSkills/> */}
              {stepInfo.length === (stepIndex + 1)? <RecommendedSkills/> : ""}
            </div>
            </div>
          </div>
      </div>
  );
}
export default RCA;