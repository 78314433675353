import { useState, useEffect } from "react";
import "./Skills.css";
import SkillItem from "./SkillItem";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import LoadingImg from "../../Media/loading.gif";

const Featured = (Props) => {
    
        const [skillItems, setSkillItems] = useState([]);
        const [formItems, setFormItems] = useState([]);
        const [selectedItems, setselectedItems] = useState([]);
        const { user, isLoading, setUser } = useAuthContext();
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            fetch(`${API}/skills?populate=*`)
              .then((resSkill) => {
                return resSkill.json();
              })
              .then((dataSkills) => {
                console.log(dataSkills.data)
                const allItems = dataSkills.data.map((item=>{
                    return item.attributes.skill_types.data
                })) 
                setSkillItems(dataSkills.data); 
                setLoading(false);
              });
          }, []);

          
       console.log(skillItems);
    return(
        <div className="skills">
            {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
                {/* <div className="head-title">{Props.headTitle}</div>
                <div className="description">{Props.desc}</div> */}
                <div className="skills-inner">
                    <div className="filter">
                    </div>
                        {skillItems?skillItems.map((item)=>{  
                            // console.log(item)
                            if(item.attributes.featured_skill === true){
                                return (<SkillItem SklItem={item.attributes} itemId={item.id}/>)
                            }  
                        }):()=>{return "There is no item in you favorite list"}}
                </div>
            </div>
    );
}
export default Featured;