import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API, STRIPE_KEY } from '../constant';
import { useAuthContext } from "../context/AuthContext";
import { useState, useEffect } from 'react';
import LoadingImg from "../Media/loading.gif";
import { getToken } from '../helpers';

// const stripePromise = loadStripe(STRIPE_KEY); // Your publishable key

const AddPaymentMethodForm = () => {
const stripe = useStripe();
const elements = useElements();
const { user, isLoading, setUser } = useAuthContext();
const [ userid, setUserId ] = useState(user.id);
const [message, setMessage] = useState();
const [loading, setLoading] = useState(false);

const fUser = async()=>{
    setLoading(true);
    try{
    const response = await fetch(`${API}/users/${userid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // set the auth token to the user's jwt
        Authorization: `Bearer: ${getToken()}`,
      },
    });
    const newUser = await response.json()
    setUser(newUser);
    setLoading(false);
    } catch (error) {
      console.error(Error);
      setLoading(false);
    } 
  }
  useEffect(() => {
  fUser();
  },[])

const handleSubmit = async (event) => {
    
    event.preventDefault();

    if (!stripe || !elements) {
    return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
    });
   
    if (error) {
        console.error(error);
    } else {
        setLoading(true);
        // Send paymentMethod.id to your backend (Strapi)
        const response = await fetch(`${API}/stripe/add-payment-method`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ card: paymentMethod.card, customerId: user.customerId, paymentMethodId: paymentMethod.id, userId:user.id }),
        });

        const data = await response.json();
        // console.log(data);
        if (response.ok) {
            setLoading(false);
            setMessage('Payment method added successfully');

        } else {
            setLoading(false);
            setMessage(`Error: ${data.error.message}`);
        }
    }
};

return (
    <form onSubmit={handleSubmit}>
        <CardElement />
        <div className="form-footer">
        <button className="theme-btn"type="submit" disabled={!stripe}>Add Payment Method</button>
        {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
        </div>
        <div className="message success">{message}</div>
        
    </form>
    );
};
export default AddPaymentMethodForm;