import Header from "../Components/Header/Header";
import { API } from "../constant";
import { useState, useEffect } from 'react';
import "./faq.css";

const Faq = () =>{

    const [pages, setPages] = useState([]);
    const [activePanel, setActivePanel] = useState(false);

    useEffect(() => {
        fetch(`${API}/Pages?populate=*`)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            const Data = data.data;
            setPages(Data); 
        });  
        
    }, []);
    const togglePanel = (e)=>{
        if(e.target.parentNode.classList.contains('active-panel'))
            e.target.parentNode.classList.remove("active-panel")
        else
            e.target.parentNode.classList.add("active-panel")
        
    }
//    console.log(pages);
    return(
        <div className="common-bg">
            <div className="container">
            <Header title="FAQ's" />
            <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="banner-content">
                            <h4 className="title">FAQ's</h4>
                            <p className="description">Lets discus some common problems and their solutions</p>
                        </div>
                        <div className="banner-right-img">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                        </div>
                    </div>
                    
                </div>
                <div className="content-wrap qc">
                
                    <div className="faq">
                        <div className="faq-inner">
                           {
                           
                            pages.map((item)=>{
                                const x = item.attributes.faq;
                                const y = x.map((childItem) =>{
                                    return(
                                        <div onClick = {togglePanel} className="panel">
                                            <div className="question">{childItem.item_question}</div>
                                            <div className="answer">{childItem.item_ans}</div>
                                        </div>
                                    )
                            })
                            return(y)
                            })
                           }
                        </div> 
                    </div>
                </div>
                
            </div>
        </div>
    );
}
export default Faq;