import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Header from "../Components/Header/Header";
import { useAuthContext } from "../context/AuthContext";
import { API } from '../constant';
import LoadingImg from "../Media/loading.gif";

const Order = () =>{
    const [order, setOrder] = useState();
    const [userId, setUserId] =useState();
    const { user, isLoading, setUser } = useAuthContext();
    const [loading, setLoading] = useState(false);
    let username;
    let email;
    let userid;
    if(user!=undefined){
        // console.log(user)
        username = user.username;
        email = user.email;
        userid = user.id;
        // setUserId(user.id);
    }
    // console.log(user);
    
    useEffect(() => {

        const fetchOrder = async () => {
            setLoading(true);
        try {
        const response = await axios.get(`${API}/orders?filters[userId][$eq]=${userid}`);
        console.log(response.data.data);
        const userOrder = response.data.data.map((item)=>{
            // return item.attributes;
            // if(item.attributes.userId.data.id == userid)
            return (
                <tr id={item.id} className="order-item">
                    <td className="order-id"><span>#{item.id}</span></td>
                    <td className="order-status"><span>{item.attributes.orderStatus}</span></td>
                    <td className="order-amout"><span>{item.attributes.amount
                    }</span></td>
                    <td><Link to ={`/orders/${item.id}`} ><button className="theme-btn">View</button></Link></td>
                </tr>
            );
                
        });
        setOrder(userOrder);
        // console.log(userid);
        setLoading(false);
        } catch (error) {
        console.error(error);
        }
        };
        
        fetchOrder();
        }, [user]);
    
    return(
            <div className="my-subscriptions order-table">
                <div className="table-responsive">
                    <table>
                    <thead>
                    <tr className="order-head order-item">
                        <th className="order-id"><span>Order Id</span></th>
                        <th className="order-status"><span>Status</span></th>
                        <th className="order-amout"><span>Amount</span></th>
                        <th className="order-link"><span>Link</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {order?order:''}
                    </tbody>
                    </table>
                </div>
            </div>   
    );
}
export default Order;