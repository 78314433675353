import { useEffect, useState } from "react";
import { API } from "../constant";
import { BASE_URL } from "../constant";
import { Link } from "react-router-dom";
import { getToken } from "../helpers";
import { IMG_URL } from "../constant";
import { useFetchAPIContext } from "../context/FetchAPIContext";

const Welcome = () =>{

    const [logo, setLogo] = useState("");
    const {data, isLoding} = useFetchAPIContext("app-information");

    console.log(data);

    useEffect(() => {   
        fetch(`${API}/app-information?populate=*`)
        .then((data) => {
          return data.json();
        })
        .then((appInfo) => {
          console.log(appInfo.data);
          setLogo(appInfo.data.attributes.app_logo.data.attributes.url);
          });
    },[])

    return(
        <div className="common-bg">
            <div className="container">
                <div className="content-wrap">
                    <div className="welcome">
                        <div className="logo">
                            <img src={logo} alt="logo"/>
                        </div>
                        <div className="heading">
                            <h2>Welcome To CopeAhead</h2>
                        </div>
                        <div className="description">
                            <p>To feel yourself comfortable and heal yourself, The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                        </div>
                        <div className="btn">
                            <Link to={getToken() ? "/ChooseOption" : "/Login"}><button className="theme-btn">Let's Get Started</button></Link>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}
export default Welcome;