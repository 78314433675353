import React, { useEffect, useState } from 'react';
import { API } from '../constant';
import { getToken } from "../helpers";
import { useAuthContext } from "../context/AuthContext";
import LoadingImg from "../Media/loading.gif";

const BillingAdress = () =>{
    const { user, isLoading, setUser } = useAuthContext();
    const [ userid, setUserId ] = useState(user.id);
    const [ formActive, setformActive ] = useState(user.id);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] =useState(user.address);
    const [city, setCity] =useState(user.city);
    const [state, setState] =useState(user.state);
    const [postalCode, setPostalCode] =useState(user.postalCode);
    const [country, setCountry] =useState(user.country);
    const [message, setMessage] =useState("");
    const [newUser, setNewUser] =useState({});



    const handleEditAddress = async (values) => {
        setLoading(true);
        const formValues = {};

        const formData = new FormData(values.target);

        formData.forEach((value, key) => formValues[key] = value);

        values.preventDefault();

        console.log(JSON.stringify(formValues));
        console.log(user.id);

        try {
          setLoading(true);
          const response = await fetch(`${API}/users/${userid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              // set the auth token to the user's jwt
              Authorization: `Bearer: ${getToken()}`,
            },
            body: JSON.stringify(formValues),
          });
          const responseData = await response.json();
    
          setUser(responseData);
          // console.log("Data saved successfully!");
          setLoading(false);
        } catch (error) {
          console.error(Error);
          console.log("Error While Updating the Profile!");
        } finally {
          setLoading(false);
          setMessage("Data updated successfully!");
          
        }
      };
      const onchangeHandler =(e)=>{
        if(e.target.name==='address')
            setAddress(e.target.value);
        else if(e.target.name==='city')
            setCity(e.target.value);
        else if(e.target.name==='state')
            setState(e.target.value);
        else if(e.target.name==='postalCode')
            setPostalCode(e.target.value);
        else if(e.target.name==='country')
            setCountry(e.target.value);
      }

      const fUser = async()=>{
        setLoading(true);
        try{
        const response = await fetch(`${API}/users/${userid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // set the auth token to the user's jwt
            Authorization: `Bearer: ${getToken()}`,
          },
        });
        const newuser = await response.json()
        setNewUser(newuser);
        setLoading(false);
        } catch (error) {
          console.error(Error);
          setLoading(false);
        } 
      }
      useEffect(() => {
      fUser();
      },[])
    console.log(newUser);
      
    return(
            <div className="billing-address">
                <div className="billing-address-inner">
                    <div className="address"><span>{newUser.address}, </span> <span>{newUser.city}, </span> <span>{newUser.state}, </span> <span>{newUser.postalCode}, </span> <span>{newUser.country}</span></div>
                    <button onClick={()=>{setformActive('active-form')}} className="theme-btn">Edit</button>
                </div>
                {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
                <div className={`theme-form popup ${formActive}`}>
                  <div className="form-wrap">
                  <button onClick={()=>{setformActive('');setMessage('')}} className=" close-btn theme-btn" type="button">X</button>
                  <div className="head-title">Edit Billing Address</div>
                  <form onSubmit={handleEditAddress}>
                      <label className="user">
                          <input onChange={onchangeHandler} className="field-with-icon" type="text" name='address' placeholder="Address line1" value={address} />
                      </label>
                      <label className="email">
                          <input onChange={onchangeHandler} className="field-with-icon" type="text" name='city' placeholder="City" value={city}/>
                      </label >
                      <label className="email">
                          <input onChange={onchangeHandler} className="field-with-icon" type="text" name='state' placeholder="State" value={state}/>
                      </label >
                      <label className="email">
                          <input onChange={onchangeHandler} className="field-with-icon" maxlength="5" type="text" name='postalCode' placeholder="Postal code" value={postalCode}/>
                      </label >
                      <label className="email">
                          <input onChange={onchangeHandler} className="field-with-icon" type="text" name='country' placeholder="Country" value={country}/>
                      </label >
                     
                      <div className="form-footer"><button className="theme-btn" type="submit">Update</button> </div> 

                      <div className="message success">{message}</div>
                  </form>
                  </div>
              </div>
              
            </div>   
    );
}
export default BillingAdress;