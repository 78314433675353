import './Header.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from "../../context/AuthContext";
import { getToken, removeToken } from '../../helpers';
import { API } from '../../constant';
import LoadingImg from "../../Media/loading.gif";

function Header(props) {
    const { user, setUser, isLoading } = useAuthContext();
    const[menuItemLink, setmenuItemLink] = useState();
    const [isActiveMenu, setActiveMenu] = useState(false);
    const [loading, setLoading] = useState(false);

    let UID;
    if(user!==undefined)
        UID=user.id;

    const toggleMenu = () => {
        setActiveMenu(!isActiveMenu);
    };
   
    const logoutFunction = ()=>{
        removeToken();
    }

    useEffect(()=>{
    const fetchUser = async()=>{
        setLoading(true);
        try {
            
            const response = await fetch(`${API}/users/${UID}?populate=subscriptions`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // set the auth token to the user's jwt
                Authorization: `Bearer: ${getToken()}`,
              },
            });
            
            const responseData = await response.json();
            
            const subscription = responseData.hasOwnProperty('subscriptions');
            if(subscription){
                if(responseData.subscriptions.length>0){
                    setmenuItemLink(<li onClick = {toggleMenu}><Link to="/dashboard">My Subscriptions</Link></li>);
                }else{
                    setmenuItemLink(<li onClick = {toggleMenu}><Link to="/go-premium">Go Premium</Link></li>);
                }
            }
            setLoading(false);
        
          } catch (error) {
            console.log(error);
          } 
        
    };

        fetchUser();
        
    },[UID]);

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    
    
    
    
    return (
            <div className={isActiveMenu ? "cope-ahead-header active" : "cope-ahead-header"}>
                {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
                <div className="cope-ahead-header-inner">
                    <div className={isActiveMenu ? "app-logo-img" : "user-img"}>{isActiveMenu ? <Link to="/"><img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/logo_icon_ea8cc666f3.png" alt="User-image"/></Link> : <Link to="/"> <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/logo_icon_ea8cc666f3.png"/></Link>}</div>
                    <div className="app-title">{props.title}</div>
                    <div className="menu-button">
                        <button className={isActiveMenu ? "close" : "open"} onClick ={toggleMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
                <div className={isActiveMenu ? "cope-ahead-menu active" : "cope-ahead-menu"}>
                    <div className="menu-items">
                        <ul>
                        {/* {isActiveMenu? mainMenu : null} */}
                        <li onClick = {toggleMenu}><Link to="/">Home</Link></li>
                        <li onClick = {toggleMenu}><Link to="/QuickCope">Quick Cope</Link></li>
                        <li onClick = {toggleMenu}><Link to="/Activities">Healing Activities</Link></li>
                        <li onClick = {toggleMenu}><Link to="/favorite-activities">Favorite Activities</Link></li>
                        {menuItemLink}
                        <li onClick = {toggleMenu}><Link to="/Settings">Settings</Link></li>
                        <li onClick = {toggleMenu}><Link to="/Contact">Contact Us</Link></li>
                        <li onClick={logoutFunction}><Link to="/Login">Logout</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
    );
}
export default Header;