import './BreathingBox.css';
import Btn from './btn';
// import BreathCounter from './BreathCounter';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { getToken } from "../../helpers";
import { API } from "../../constant";
import { useAuthContext } from "../../context/AuthContext";

function BreathingBox(items) {

  const { user, isLoading, setUser } = useAuthContext();
  const breathNumbers = [0, 1, 2, 3, 4];
  const breathItems = ["Hold","Breathe Out","Hold"];
  const animationItems = ["hold-breath-in","animation-ease-out", "hold-breath-out "];
  const [comp , setcomp] = useState("");
  const [animation , setAnimation] = useState("");
  const breathCount = breathNumbers.map((item, index) => {
    return <div>{item}</div>
  })
 
  const starCounter =(event)=>{
    setcomp("Breath In");
    setAnimation("animation-ease-in");
    breathItems.forEach((item, index) => {
      setTimeout(() => {
        setcomp(item);
      }, 4000*(index+1))
    })
    animationItems.forEach((item, index) => {
      setTimeout(() => {
        setAnimation(item);
      }, 4000*(index+1))
    })
    
    const numCount = document.getElementById("num-count");
    const dotCount = document.getElementById("dot-count");
    numCount.classList.add("add-num-animation");
    dotCount.classList.add("add-dot-animation");
    setTimeout(function() {endCounter(); }, 16000);
  }
  const endCounter =()=>{
    const numCount = document.getElementById("num-count");
    const dotCount = document.getElementById("dot-count");
    numCount.classList.remove("add-num-animation");
    dotCount.classList.remove("add-dot-animation");
    // setcomp("Breath In");
  }
  let userId;
  if(user !=undefined){
    userId = user.id;
  }
  // console.log(user)
  const finishButton = ()=>{

    fetch(`${API}/users/${userId}?populate=*`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let completed =[];
        data.completed_skill.map((item, index)=>{
            completed[index] = {id:item.id}
        })
        completed.push({id: items.SkillId});
        const userResponse = fetch(`${API}/users/${userId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              // set the auth token to the user's jwt
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(
              {  
                 
                  completed_skill: completed
              }
            ),
          });
        
      });
}
    return (
      
      <div className="breath-box content-wrap active">
          {/* <div className="topbar">
            <h3>Lets Practice Box Breathing</h3>
          </div> */}
          <div className="breath-box-inner">
          <div className={`breath-box ${animation}`}  onClick={starCounter}>
            <div className="title-div">
            <div>
                <h2 className="title">{comp}</h2>
            </div>
            
            </div>
            <div className="breath-dot">
              <div className='box-dot' id="dot-count"></div>
              <div className="count-div" >
                <h2 className="breath-count" id="num-count">{breathCount}</h2> 
              </div>
            </div> 
          </div>
          <div className="footer">
            <button className="theme-btn" onClick={starCounter}>Continuous Box Breathing</button> 
            <Link to="./../../chooseOption"><p className="theme-btn2" onClick={finishButton}>Exit</p></Link>
             
          </div>
        
      </div> 
      </div>
    );
  }
  
  export default BreathingBox;
