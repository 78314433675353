import Header from "../Components/Header/Header";
import { Link } from 'react-router-dom';
import { useAuthContext } from "../context/AuthContext";

const ChooseOption = () =>{

    const { user, isLoading, setUser } = useAuthContext();
    let userName;
    if(user !=undefined)
    userName = user.username;
    return(
        <div className="common-bg">
            <div className="container">
                <Header title ="Welcome" />
                <div className="content-wrap">
                    <div className="option-box-wrap">
                        <div className="option-wrap-inner">
                        <h2 className="title">{`Hello, ${userName}`}</h2>
                        <p className="description">To feel yourself comfortable, what would you like to to do ?</p>
                            <div className="option-box-inner">
                            <div className="option-box">
                                <Link to="/QuickCope">
                                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_1940f4c30c.svg" alt="quick-cope"/>
                                </Link>
                            </div>
                            <div className="option-box">
                                <Link to="/Activities">
                                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/healing_activity_a026dfca3e.svg" alt="healing-activity"/>
                                </Link>
                            </div>
                            <div className="option-box">
                                <Link to="/RCA">
                                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/personal_activity_5a280e261a.svg" alt="personal-activity"/>
                                </Link>
                            </div>
                            <div className="option-box">
                                <Link to="/Contact">
                                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/sos_ec00872f93.svg" alt="sos"/>
                                </Link>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}
export default ChooseOption;