import React, { Fragment, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { setToken } from "../../helpers";
import { Link } from "react-router-dom";
import LoadingImg from "../../Media/loading.gif";
    
const SignUpForm = () => {
  const { setUser } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const formValues = {};
    const formData = new FormData(values.target);
    formData.forEach((value, key) => formValues[key] = value);
    values.preventDefault();
    // setIsLoading(true);
    console.log(formData);
    
    try {
      setLoading(true);
      const response = await fetch(`${API}/auth/local/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      const data = await response.json();
      console.log(data);
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);

        // set the user
        setUser(data.user);

      console.log(`Welcome to Social Cards ${data.user.username}!`);
      //console.log(formValues);
      const email = formValues.email
      const response1 = await fetch(`${API}/email/user-registration`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify( formValues ),
      });
      const data1 = await response1.json();
      console.log(data1);

      // navigate("/profile", { replace: true });
      window.location.replace("/");
      } 
      
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Something went wrong!");
      setLoading(false);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };
  const showPassword = (e)=>{
    let inputval = e.target.previousSibling.getAttribute("type");
    if(inputval == "password"){
        e.target.previousSibling.setAttribute("type", "text");
        e.target.classList.remove("hide");
    }else{
        e.target.previousSibling.setAttribute("type", "password");
        e.target.classList.add("hide");
    }
        
}
  return (
    <div className="login-area-inner">
      {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
    <div className="theme-form">
        <form className="register-form" onSubmit={onFinish}>
        
            <input hidden className="field-with-icon" type="text" name='username' value={`${firstName} ${lastName}`} placeholder="First Name" required/>
          
            <label className="user">
                <input onChange={(e)=>{setFirstName(e.target.value)}} className="field-with-icon" type="text" name='firstname' placeholder="First Name" required/>
            </label>
            <label className="user">
                <input onChange={(e)=>{setLastName(e.target.value)}}className="field-with-icon" type="text" name='lastname' placeholder="Last Name"/>
            </label>
            <label className="email">
                <input className="field-with-icon" type="email" name='email' placeholder="Email Address" required/>
            </label>
            <label className="password">
                <input className="field-with-icon" type="password" name='password' placeholder="Password" required/>
                <span onClick={showPassword} className="eye-icon hide"></span>
            </label>
            <label className="password">                
              <input className="field-with-icon" type="password" name='password' placeholder="Confirm Password" required/>
              <span onClick={showPassword} className="eye-icon hide"></span>
            </label>
            <div className="check-wrap">
              <div className="checkbox"><input type="checkbox" value="I accept Cope Ahead Terms & Conditions" required/><div>I accept Cope Ahead <Link to="/terms-and-conditions">Terms & Conditions</Link></div></div>
                <div className="checkbox"><input type="checkbox" value="I understand Cope Ahead is not a replacement for therapy" required/>I understand Cope Ahead is not a replacement for therapy</div>
            </div>
            <button id="submit" className="theme-btn" type="submit">Register</button>
              <div className="error-message">{error}</div>
        </form>
    </div>
</div>
  );
};


export default SignUpForm;