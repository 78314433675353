import Header from "../Components/Header/Header";
import User from "../Components/User/User";
import { useAuthContext } from "../context/AuthContext";
// import { getToken } from "../helpers";

const Settings = () =>{
    const { user, isLoading, setUser } = useAuthContext();
    let username;
    let email;
    if(user!=undefined){
        // console.log(user)
        username = user.username;
        email = user.email;
    }
    
    const userInfo = [
        {
            userName: username,
            emailId: email,
        }

    ];
    return(
        <div className="common-bg skills">
            <div className="container">
                <Header title="Settings"/>
                <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="banner-content">
                            {/* <h4 className="title">Sorry!!! <br/>You Feel That way</h4>
                            <p className="description">Lets take a few breathes and try these coping mechanisms</p> */}
                        </div>
                        <div className="banner-right-img">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                        </div>
                    </div>
                    
                </div>
                <User user={userInfo}/>
            </div>
        </div>
    );
}
export default Settings;