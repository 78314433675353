import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { API, BASE_URL } from "../../constant";
import { getToken } from "../../helpers";
import IMG from "../../Media/ph_heart.svg";
import LoadingImg from "../../Media/loading.gif";


const SkillItem =(props)=>{
  // const fun =()=>{
  //    return props;
  // }
   


  // console.log(props)

  const { user, isLoading, setUser } = useAuthContext();
  const [isLikeButton, setLikeButton ] = useState();
  const [loading, setLoading] = useState(false);
  const userData = props.SklItem.users.data;

  // console.log(user.id);

  useEffect(()=>{
    if(userData.length > 0){
      userData.map((item)=>{
        if(user !== undefined || user > 0){
          if(item.id === user.id )
            setLikeButton(1);      
          else
            setLikeButton(0);
        }
      });
    } 
  },[userData])
    
    
    const updateLikeButton = async (itemId)=>{
      setLoading(true);
        try {
            const skillResponse = await fetch(`${API}/users/${user.id}?populate=skills`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // set the auth token to the user's jwt
                Authorization: `Bearer ${getToken()}`,
              }
            });
            
            const responseSkillData = await skillResponse.json();
            let relatedSkill=[];

            responseSkillData.skills.map((item1, index) =>{
                relatedSkill[index] = { id: item1.id };
            });
            console.log(relatedSkill);
            let index1;
            relatedSkill.map((item, index) => {
              if(item.id === itemId)
              index1 = index;
            })
            // const index1 = relatedSkill.findIndex(item => item.id === itemId);
            // console.log(index1);
            if (index1 > -1) {
            // ID exists, remove the object
            relatedSkill.splice(index1, 1 );
            setLikeButton(0);
            setLoading(false);
            } else {
            // ID doesn't exist, add the new object
            relatedSkill.push({id:itemId });
            setLikeButton(1);
            setLoading(true);
            }
            
            const userResponse = await fetch(`${API}/users/${user.id}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  // set the auth token to the user's jwt
                  Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(
                  {    
                      skills: relatedSkill
                  }
                ),
              });

            const responseUserData = await userResponse.json();

          } catch (error) {
            console.error(Error);
          } finally {
            setLoading(false);
          }
        };
        // console.log(user)
        const completed = props.SklItem.completed.data.map((item)=>{
          if(user !== undefined || user > 0){
          if(item.id == user.id){
            return true
          }else{
            return false
          }
        }
        })
        
    return( 
        <div className="skill-item">
          {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
            <Link to ={`/skills/${props.SklItem.slug}`} >
            <div className="content-left">
                <div className="skill-img">
                    <img src={(props.SklItem.Skill_Image.data) ? `${props.SklItem.Skill_Image.data.attributes.url}`:`#`} alt="skill-img"/>
                    </div>
                <div className="skill-content">
                    <div className="skill-title">
                        <span>
                        { (props.SklItem.Title) ? props.SklItem.Title : `` }
                        </span>
                        <span>
                        { (completed[0] ==true)?<span className="completed">Completed</span>: (props.SklItem.Complete_Time) ? `${props.SklItem.Complete_Time} min` : `` } 
                        </span>
                    </div>
                    <div className="related-stress"><span>Related Stressor: </span> 
                    {props.SklItem.skill_types.data.map((item, index)=>{   
                        
                        return ( 
                        <span>{item.attributes.Category}
                        {props.SklItem.skill_types.data.length === index + 1 ? '' : ', '}</span> 
                        )
                    })}
                    
                    </div>
                </div>

            </div>
            </Link>
            
            <button onClick = {( )=>{ 
                updateLikeButton(props.itemId);
            }} className={`like-button ${isLikeButton === 1 ? "active" : "innactive" }`} >
                <span className="ph-heart"><img src={IMG} alt="heart"/></span>
            </button>
        </div>
    );
}
export default SkillItem;