import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SignUpForm from "./SignUpForm";
// import StripeSubscription from "../Stripe/StripeSubscription";

const SignUp = () => {

  // const [plan, setPlan] = useState("");
  // const [element, setElement] = useState("free");

  // const choosePlanHandler =(e) =>{
  //   console.log(e.target.value);
  //   setPlan(e.target.value);
  // }
  // useEffect(() => {
  //   if(plan==="free")
  //     setElement(<SignUpForm/>);
  //   else if(plan==="paid")
  //     setElement(<StripeSubscription/>);
  // }, [plan]);

return (
  <div>
    <div className="login-register-head">
        <h3 className="title">Register</h3>
        <img src={`https://copeaheadbucket.s3.us-east-1.amazonaws.com/login_register_17e4e90797.svg`} alt="login-register"/>
        <div className="login-register-switcher">
            <span className="inactive"><Link to="../Login">Login</Link></span><span className="active"><Link to ="../SignUP">Register</Link></span>
      </div>
    </div>
    <div class="theme-form">
      <div class="register-form">
      {/* <label className="choosePlan">                
        <select onChange={choosePlanHandler} className="field-with-icon" name="choose-plan" id="plan" required >
          <option value="">Select your plan type</option>
          <option value="free">Get started with a free plan</option>
          <option value="paid">Get started with a basic paid plan</option>
        </select>
      </label> */}
      {/* {element} */}
      <SignUpForm/>
      </div>

    </div>
  </div>
)
};
export default SignUp;