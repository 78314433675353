import { useState, useEffect } from "react";
import "./Skills.css";
import SkillItem from "./SkillItem";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";

const Liked = () => {
    
        const [skillItems, setSkillItems] = useState([]);
        const { user, isLoading, setUser } = useAuthContext();
        
        let userId;
        if(user !=undefined)
        userId = user.id;

        // console.log(userId);

        useEffect(() => {
            fetch(`${API}/skills?populate=*`)
              .then((resSkill) => {
                return resSkill.json();
              })
              .then((dataSkills) => {
                // console.log(dataSkills.data)
                const allItems = dataSkills.data.map((item=>{
                    return item.attributes.skill_types.data
                })) 
                setSkillItems(dataSkills.data); 
                
              });
          }, []);
        //   console.log(skillItems)
    return(
        <div className="skills">
                {/* <div className="head-title"></div>
                <div className="description"></div> */}
                <div className="skills-inner">
                    <div className="filter">
                    </div>
                        {skillItems.map((item)=>{  
                            let itter = false;
                            const x = item.attributes.users.data.map((userItem)=>{
                                // console.log(item.attributes.users.data.length) 
                                if(item.attributes.users.data.length > 0){
                                    if(userItem.id === userId){     
                                        itter = true;
                                    }  
                                }
                                
                            })
                            if(itter===true)
                                return (<SkillItem SklItem={item.attributes} itemId={item.id}/>)
                        })}
                </div>
            </div>
    );

}
export default Liked;