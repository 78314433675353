import Login from "../Components/Login/Login";
const Loginpage = () =>{
    return(
        <div className="common-bg">
            <div className="container">
                <div className="content-wrap white-bg">
                    <Login />
                </div>
                <div className="footer-main">
                        <div className="mask-with-ellips">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/login_register_bottom_1a090d4194.svg" alt="bg"/>
                        </div>
                        <div className="login-register-footer"></div>  
                    </div>
            </div>
        </div>
    );
}
export default Loginpage;
