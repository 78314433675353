import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Header from "../Components/Header/Header";
import { useAuthContext } from "../context/AuthContext";
import Subscription from './subscription';
import BillingAdress from './billingAddress';
import PaymentMethod from './paymentMethod';
import { Link } from 'react-router-dom';
import Order from './order';
import LoadingImg from "../Media/loading.gif";

const Dashboard = () =>{
    const [subscription, setSubscription] = useState();
    const [navActive, setNavActive] = useState('My Orders');
    const [content, setContent] = useState(<Order/>);
    const [dataFromChild, setDataFromChild] =useState('');
    const { user, isLoading, setUser } = useAuthContext();
    const [loading, setLoading] = useState(false);
    let username;
    let email;
    let userid;
    if(user!=undefined){
        // console.log(user)
        username = user.username;
        email = user.email;
        userid = user.id;
        // setUserId(user.id);
    }
    const handleDataFromChild = (data) => {
        setDataFromChild(data);
        };
    const navItemHandler=(e)=>{
        if (e.target.innerHTML ==='My Orders'){
            setNavActive('My Orders');
            setContent(<Order/>);
        }else if(e.target.innerHTML ==='My Subscriptions'){
            setNavActive('My Subscriptions');
            setContent(<Subscription onDataFromChild={handleDataFromChild}/>);
        }else if (e.target.innerHTML ==='Address'){
            setNavActive('Address');
            setContent(<BillingAdress/>);
        }else if (e.target.innerHTML ==='Payment methods'){
            setNavActive('Payment methods');
            setContent(<PaymentMethod/>);
        }
    }
// console.log(dataFromChild);
    return(
        <div id={userid} className="common-bg">
            <div className="common-inner white-bg">
            {dataFromChild}
            {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
            <div className="container">
                <Header title="Dashboard"/>
                <div className="content-wrap">
                    <div className="user-settings">
                        <div className="user-dashboard">
                            <div className="user-name">{username}</div>
                            <div className="user-email">{email}</div>
                        </div>
                    </div>
                    <div className="my-subscriptions">
                        <div className="subscription-nav">
                        <div onClick={navItemHandler} className={`subscription-nav-item ${(navActive==='My Orders')?"active-item":""}`} >My Orders</div>
                            <div onClick={navItemHandler} className={`subscription-nav-item ${(navActive==='My Subscriptions')?"active-item":""}`} >My Subscriptions</div>
                            <div onClick={navItemHandler} className={`subscription-nav-item ${(navActive==='Address')?"active-item":""}`} >Address</div>
                            <div onClick={navItemHandler} className={`subscription-nav-item ${(navActive==='Payment methods')?"active-item":""}`} >Payment methods</div>
                        </div>
                        {content}
                    </div>
                </div> 
            </div>
            </div>
        </div>
        
    );
}
export default Dashboard;