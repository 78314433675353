import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Header from "../Components/Header/Header";
import { useAuthContext } from "../context/AuthContext";
import LoadingImg from "../Media/loading.gif";

const OrderDetails = () =>{
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    // console.log(user);
    
    useEffect(() => {

        const fetchOrder = async () => {
        try {
        setLoading(true);
        const responseData = await axios.get(`https://admin.paritoshpareek.net/api/orders/${id}`);
        console.log(responseData.data.data);
        const orderInfo = responseData.data.data.attributes;
        setOrder (
        <div className="items">
            <div className="subscription-item">
                <div className="order-id"><span>Order Id :</span> <span>{id}</span></div>
                <div className="order-amount"><span>Amount :</span> {orderInfo.amount}</div>
                <div className="order-quantity"><span>Status :</span> {orderInfo.orderStatus}</div>
                <div className="order-description"><span>Description :</span> {orderInfo.description}</div>
            </div>
            <div className="head-title">Other Details</div>
            <div className="subscription-item">
                <div className="charge-status"><span>Charge Status :</span> <span>{orderInfo.chargeStatus}</span></div>
                <div className="subscription-payment-status"><span>Subscription Payment :</span>{orderInfo.subscriptionPaymentStatus}</div>
            </div>
        </div>);
        
        // setSubscription(responseData.data.data.attributes);
        setLoading(false);
        } catch (error) {
        setLoading(false);
        console.error(error);
        }
        };
        
        fetchOrder();
        }, [id]);
   
    return(
            <div className="common-bg white-bg  ">
                <div className="container">
                {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
                    <Header title="My Orders"/>
                    <div className="content-wrap">
                     <div className="my-subscriptions details">
                        <div className="head-title">Order Details</div>
                            {order}
                        <div className="footer links">
                            
                            <Link to="/dashboard"><button className="theme-btn">Go Back</button></Link>
                        </div>
                    </div>  
                    </div>
                </div>
            </div>
    );
}
export default OrderDetails;