import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useAuthContext } from '../../context/AuthContext';
import LoadingImg from "../../Media/loading.gif";

const StripeSubscription = () => {
const [plan, setPlan] = useState();
const { user, isLoading, setUser } = useAuthContext(); 
const [loading, setLoading] = useState(false);
const stripePromise = loadStripe('pk_test_GhEdsltgdfRISZ3HZAR7xuE3');

useEffect(() => {
const fetchPlan = async () => {
try {
const response = await axios.get('https://admin.paritoshpareek.net/api/plans/7');
console.log(response.data.data.attributes);
setPlan(response.data.data.attributes);
} catch (error) {
console.error('Error fetching plan:', error);
}
};
setLoading(false);
fetchPlan();
}, []);
// {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
if (!plan) return <div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>;
if(isLoading) return <div>Loading...</div>;

return (
<div className="plan-details">
<p className="heading">{plan.name}</p>
<p>Amount: ${plan.amount}</p>
<p>Interval: {plan.interval}</p>
<Elements stripe={stripePromise}>
<CheckoutForm plan={plan} userId={user}/>
</Elements>
</div>
);
};

export default StripeSubscription;