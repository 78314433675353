import Header from "../Components/Header/Header";
// import RecommendedSkills from "../Components/Skills/RecommendedSkills";
import Liked from "../Components/Skills/Liked";
import FilterSkills from "../Components/Skills/FilterSkills";

const FavoriteActivities = () =>{
    return(
        <div className="common-bg skills">
            <div className="container">
                <Header title ="Favourite Activities" />
                <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="banner-content">
                            <h4 className="title">Favourite Activities</h4>
                            <p className="description">Lets take a few breathes and try these coping mechanisms</p>
                        </div>
                        <div className="banner-right-img">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                        </div>
                    </div>
                    
                </div>
                <div className="content-wrap qc">
                    <div className="content-wrap-inner white-bg">
                    <Liked type="ToolBox" headTitle="My tool box"/>
                    </div>
                    {/* <FilterSkills headTitle="Skills"/> */}
                </div>
                
            </div>
        </div>
    );
}
export default FavoriteActivities;