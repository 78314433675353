import React, { useState } from 'react';
import { API } from '../../constant';
import { Link } from 'react-router-dom';
import LoadingImg from "../../Media/loading.gif";

const ResetPassword = () => {

    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [passwordSet, setpasswordSet] = useState('');
    const [loading, setLoading] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

const handleResetPassword = async (e) => {
    e.preventDefault();
   
    try {
        setLoading(true);
        const response = await fetch(`${API}/email/reset-password`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, password }),
        });

        const data = await response.json();
        setpasswordSet(data);
        setMessage(data.message);
        setLoading(false);

    } catch (error) {
        console.error('Error:', error);
        setMessage('Error resetting password');
        setLoading(false);
    }
};
const showPassword = (e)=>{
    let inputval = e.target.previousSibling.getAttribute("type");
    if(inputval == "password"){
        e.target.previousSibling.setAttribute("type", "text");
        e.target.classList.remove("hide");
    }else{
        e.target.previousSibling.setAttribute("type", "password");
        e.target.classList.add("hide");
    }
        
}
const content = ()=>{
    if(message){
        return <div>
                    <div>Your password reset successfully</div>
                    <Link to="/Login">Go to login page</Link>
                </div>
    }else if(passwordSet.error){
        return <div className="error-message">{passwordSet.error.message}</div>
    }else{
        return <form onSubmit={handleResetPassword}>
                    <label className="password">
                        <input className="field-with-icon" type="password" value={password} name='resetPassword' placeholder="Enter new password" onChange={(e) => setPassword(e.target.value)} required/>
                        <span onClick={showPassword} className="eye-icon hide"></span>
                    </label>
                    <button className="theme-btn" type="submit">Reset Password</button>
                    
                </form>
    }
}
return(
    <div className="common-bg">
        {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
        <div className="container forget-reset-password">
        <div className="banner-box">
                <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                <div className="banner-wrap">
                    <div className="banner-content">
                        <h4 className="title">Reset your password</h4>
                    </div>
                    <div className="banner-right-img">
                        <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                    </div>
                </div>
                
            </div>
            <div className="content-wrap white-bg">
                <div className="theme-form">
                    {content()}
                </div>
                <div className="footer-main">
                    <div className="mask-with-ellips">
                        <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/login_register_bottom_1a090d4194.svg" alt="bg"/>
                    </div>
                    <div className="login-register-footer"></div>  
                </div>
            </div>
        </div>
    </div>
);
};

export default ResetPassword;