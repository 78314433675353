import React from "react";
    import { useAuthContext } from "../../context/AuthContext";
    import { API } from "../../constant";
    import { useState } from "react";
    import { getToken } from "../../helpers";
    import Header from "../../Components/Header/Header";
    import { Link } from "react-router-dom";
    
    const Profile = () => {
      const [loading, setLoading] = useState(false);
      const { user, isLoading, setUser } = useAuthContext();
      let userName;
      let emailaddr
      if(user !=undefined){
        userName = user.username;
        emailaddr = user.email
      }
      
    
    
      const handleProfileUpdate = async (values) => {
        setLoading(true);
        const formValues = {};

        const formData = new FormData(values.target);

        formData.forEach((value, key) => formValues[key] = value);

        values.preventDefault();

        console.log(JSON.stringify(formValues));

        try {
          const response = await fetch(`${API}/users/${user.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              // set the auth token to the user's jwt
              Authorization: `Bearer: ${getToken()}`,
            },
            body: JSON.stringify(formValues),
          });
          const responseData = await response.json();
    
          setUser(responseData);
          console.log("Data saved successfully!");
        } catch (error) {
          console.error(Error);
          console.log("Error While Updating the Profile!");
        } finally {
          setLoading(false);
        }
      };
      const showPassword = (e)=>{
        let inputval = e.target.previousSibling.getAttribute("type");
        if(inputval == "password"){
            e.target.previousSibling.setAttribute("type", "text");
            e.target.classList.remove("hide");
        }else{
            e.target.previousSibling.setAttribute("type", "password");
            e.target.classList.add("hide");
        }
            
    }
      return (

        <div className="common-bg skills">
            <div className="container">
            <Header title ={`Hello ${userName}`} />
                <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="banner-content">
                            <h4 className="title">Sorry!!! <br/>You Feel That way</h4>
                            <p className="description">Lets take a few breathes and try these coping mechanisms</p>
                        </div>
                        <div className="banner-right-img">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/banner_stress_image_a5d244e358.svg" alt="banner-img"/>
                        </div>
                    </div>
                    
                </div>
                <div className="content-wrap qc">
                
            <div className="profile-area-inner">
              
              <div className="theme-form">
                  <form onSubmit={handleProfileUpdate}>
                      <label className="user">
                          <input className="field-with-icon" type="text" name='username' placeholder="Enter Your Username" value={userName} />
                      </label>
                      <label className="email">
                          <input className="field-with-icon" type="email" name='email' placeholder="Enter Your Email" value={emailaddr}/>
                      </label >
                      <label className="password">
                          <input className="field-with-icon" type="password" name='password' placeholder="Enter Your Password" />
                          <span onClick={showPassword} className="eye-icon hide"></span>
                      </label>
                     
                      <button class="theme-btn" type="submit">Update</button>
                    
                  </form>
              </div>
            </div>
              </div>
            </div>
        </div>

        
      );
    };
    
    export default Profile;
    