import React, {useEffect, useState} from "react";
import  CarouselItem  from "./CarouselItem";
import "./Carousel.css";
import { Link } from "react-router-dom";
import { getToken } from "../../helpers";
import { API } from "../../constant";
import { useAuthContext } from "../../context/AuthContext";

const Carousel = (items) => {

    const { user, isLoading, setUser } = useAuthContext();
    const [activeIndex, setActiveIndex] = useState(0);

    const updateIndex= (newIndex) =>{
        if(newIndex < 0){
            newIndex = 0;
        }else if(newIndex >= items.Items.length){
            newIndex = items.Items.length -1;
        }
        setActiveIndex(newIndex);
    }

    const finishButton = ()=>{

        fetch(`${API}/users/${user.id}?populate=*`)
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            let completed =[];
            data.completed_skill.map((item, index)=>{
                completed[index] = {id:item.id}
            })
            completed.push({id: items.SkillId});
            const userResponse = fetch(`${API}/users/${user.id}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  // set the auth token to the user's jwt
                  Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(
                  {  
                     
                      completed_skill: completed
                  }
                ),
              });
            
          });
    }
  
    return(
            <div className="content-wrap">
                <div className="content-wrap-inner white-bg">
                <div className="indicators">
                    {items.Items.map((item, index)=>{
                        return(
                            <button onClick = {()=>{
                                updateIndex(index);
                            }} className="indicator-buttons">
                                <span className={`material-symbols-outlined ${index===activeIndex? "indicator-symbol-active":"indicator-symbol"}`}></span>
                            </button>
                        );
                    })}
                
                </div>
                
                <div className="carousel">
                <Link to="/"><div className="close-button"><span></span></div></Link>
                    <div className="inner"
                        style={{transform:`translate(-${activeIndex * 100}%)`}}>
                        {items.Items.map((item, index) => {
                            return <CarouselItem skillItem={item} ItemIndex={index} Len={items.Items.length} />;
                        })}
                    </div>
                    
                       
                </div>
                <div className="carousel-buttons">
                        <button onClick = {()=>{
                            updateIndex(activeIndex -1);
                        }} className="button-left-arrow"><span></span></button>
                        <button onClick = {()=>{
                            updateIndex(activeIndex +1);
                        }} className="button-right-arrow"><span></span></button>
                    </div>
                    </div>
                       {/* <div className="finish-button"><Link to="/chooseOption">{finishButton()}</Link></div> */}
                       <div className="finish-button"><Link to="/chooseOption">{(items.Items.length -1 == activeIndex)? <button onClick= {finishButton} className="theme-btn finish-btn">Finish</button>:""}</Link></div>
            </div>
                
    );
};
export default Carousel;