import Header from "../Components/Header/Header";
import FilterSkills from "../Components/Skills/FilterSkills";
import { useAuthContext } from "../context/AuthContext";

const Activities = () =>{
    const { user, isLoading, setUser } = useAuthContext();
    let userName;
    if(user !=undefined)
    userName = user.username;
    return(
        <div className="common-bg activities">
            <div className="container">
                <Header title ={`Hello ${userName}`} />
                <div className="content-wrap white-bg">
                    <div className="activities logo">
                        <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/Logo_cope_ahead_55cf010d58.svg" alt="logo"/>
                    </div>
                    <FilterSkills headTitle="Activities"/>
                </div>
                
            </div>
        </div>
    );
}
export default Activities;