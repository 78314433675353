import Header from "../Components/Header/Header";
import { Link } from 'react-router-dom';
import { BASE_URL } from "../constant";
import { useAuthContext } from "../context/AuthContext";

const Contact = (props) =>{

    const { user, isLoading, setUser } = useAuthContext();
    let userName;
    if(user !=undefined)
    userName = user.username;
    // console.log(props.Data);
    return(
        <div className="common-bg">
            <div className="container">
            <Header title ={`Hello, ${userName}`} />
                <div className="content-wrap white-bg contact">
                <div className="contact-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/contact_27558e943e_2fe6789499.svg" alt="qc-banner"/>
                    <h2 className="title">Don’t Worry!!!</h2>
                    <h4>Everything Is Going To Be OK</h4>
                    <p>At this time we suggest contacting someone who can help you immediately.</p>
                    <p>For medical emergencies, <span>Dial 911.</span><br/>
For the US Suicide Hotline, <span>Dial 988.</span></p>
                    
                </div>
                    <div className="featured-box">
                        <div className="featured-box-inner">
                      
                        <Link to="/QuickCope"><span className="theme-btn">Let’s Do Some Cope Exercise</span></Link>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Contact;