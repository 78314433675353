import { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import Carousel from "../Components/Carousel/Carousel";
import BreathingBox from "../Components/BreathBox/BreathingBox";
import { useParams } from "react-router-dom";
// import { render } from "@testing-library/react";
import Contact from "./Contact";
import { useAuthContext } from "../context/AuthContext";
import { API } from "../constant";
// import   fun  from "../Components/Skills/SkillItem";




const StopSkill =() => {

    const param  = useParams();
    
    const [carouselItems, setCarouselItem] = useState([]);
    const [templete, settemplete] = useState([]);
    const[content, setcontent] = useState([]);
    const[skillId, setSkillId] = useState();
    const[skillImage, setSkillImage] = useState();
    const { user, isLoading, setUser } = useAuthContext();
    let userName;
    if(user !=undefined)
    userName = user.username;

    // console.log(fun);
    
        useEffect(() => {
          fetch(`${API}/skills?filters[slug][$eq]=${param.slug}&populate=skill_step.image`)
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              console.log(data.data[0].attributes);
              setCarouselItem(data.data[0].attributes.skill_step);
              settemplete(data.data[0].attributes.skill_templates);
              setcontent(data.data[0].attributes);
              setSkillId(data.data[0].id);
              setSkillImage(data.data[0].attributes);
              console.log(data.data[0])
            });
        }, []);
       function renderSwitch(param){
        switch (param) {
          case 'box_breathing':
            return <BreathingBox SkillId ={skillId}/> 
          case 'simple_box':
            return <Contact Data ={content}/>
          default:
            return <Carousel Items = {carouselItems} SkillId ={skillId} />;
          }
        }
        // let x = param.slug.replace(/[-,_]/g , ' ')
    return(
        <div className="common-bg skills">
            <div className={`container skill ${param.slug}`}>
                <Header title={`Hello ${userName}`}/>
                <div className="banner-box">
                    <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/quick_cope_img_ff5aa6efff.svg" alt="qc-banner"/>
                    <div className="banner-wrap">
                        <div className="banner-content">
                            { <h4 className="title">{param.slug.replace(/[-,_]/g , ' ')}</h4> }
                        </div>
                        <div className="banner-right-img">
                            <img src="https://copeaheadbucket.s3.us-east-1.amazonaws.com/stop_skill_banner_82b639057e.svg"/>
                        </div>
                    </div>
                    
                </div>
                {renderSwitch(templete)}
            </div>  
        </div>
    );
}
export default StopSkill;