import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, Route } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { setToken, getToken } from "../../helpers";
import "./Login.css";
import ChooseOption from "../../fragments/ChooseOption";
import LoadingImg from "../../Media/loading.gif";

function Login(){
    const {tok, setTok} =useState(false);
    const navigate = useNavigate();
    const { setUser } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
      
        const onFinish = async (values) => {

            // setIsLoading(true);

            const formValues = {};

            const formData = new FormData(values.target);

            formData.forEach((value, key) => formValues[key] = value);

            values.preventDefault();

            console.log(JSON.stringify(formValues));
            
           
            try {
                setLoading(true);
                const value = {
                    identifier: formValues.email,
                    password: formValues.password,
                };
                const response = await fetch(`${API}/auth/local`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
                });
        
                const data = await response.json();
                if (data?.error) {
                throw data?.error;
                console.log(data.error);
                } else {
               
                setToken(data.jwt);
        
                // set the user
                setUser(data.user);
        
                window.location.replace("/");
                }
                // setIsLoading(false);
                setLoading(false);
            } catch (error) {
                console.error(error);
                if(error.message=="fail to fetch")
                setError( "Something went wrong, try again");
                else
                setError(error.message);
                // setIsLoading(false);
                setLoading(false);
            } finally {
                // setIsLoading(false);
                setLoading(false);
                
            }
            };
    const showPassword = (e)=>{
        let inputval = e.target.previousSibling.getAttribute("type");
        if(inputval == "password"){
            e.target.previousSibling.setAttribute("type", "text");
            e.target.classList.remove("hide");
        }else{
            e.target.previousSibling.setAttribute("type", "password");
            e.target.classList.add("hide");
        }
            
    }
        
    return(
    <div className="login-area-inner">
        {loading?<div className="lodding"><div className="lodding-text"><img src={LoadingImg} alt="Loading..." /></div></div>:""}
        <div className="login-register-head">
            <h3 className="title">Login</h3>
            <img src={`https://copeaheadbucket.s3.us-east-1.amazonaws.com/login_register_17e4e90797.svg`} alt="login-register"/>
            <div className="login-register-switcher">
                <span className="active"><Link to="../Login">Login</Link></span><span className="inactive"><Link to ="../SignUP">Register</Link></span>
            </div>
        </div> 
        <div className="theme-form">
            <form onSubmit={onFinish}>
                <label className="email">
                    {/* <span className="user-icon"></span> */}
                    <input className="field-with-icon" type="text" name="email" placeholder="Email Address"  required/>
        
                </label>
                <label className="password">
                    {/* <span className="lock-icon"></span> */}
                    <input className="field-with-icon" type="password" name="password" placeholder="Password"  required/>
                    <span onClick={showPassword} className="eye-icon hide"></span>
                </label>
                {error?<div id="errorMessage">{error}</div>:""}
                <span className="flex-wrap">
                    <span className="checkbox"><input type="checkbox" value="rememberme"/>Remember Me</span>
                    <span className="forget-password-link"><Link to="/forgot-password">Forgot Password?</Link></span>
                </span>
                <button className="theme-btn" type="submit">Login</button>
            </form>
        </div>
    </div>
    );
}

export default Login;